import { Injectable } from '@angular/core';
import { GridConfig } from '../models/column-configurator.model';
import { Subject } from 'rxjs';
import { DEFAULT_CONVERSATION_LIST_CONFIG } from 'src/app/insights/proxy/conversation.model';
import { getStorageKey } from '../../designer/utils';
import { COLUMN_CONFIGURATOR_KEY } from '../../shared.consts';
import { ConfigStateService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class ColumnConfiguratorService {
  private get _storageKey(): string {
    return getStorageKey(this.config, COLUMN_CONFIGURATOR_KEY);
  }

  private get _defaultGridConfigs(): GridConfig[] {
    return [DEFAULT_CONVERSATION_LIST_CONFIG];
  }

  private get _gridConfigs(): GridConfig[] {
    const gridConfigsStr = localStorage.getItem(this._storageKey);
    return gridConfigsStr ? JSON.parse(gridConfigsStr) : [];
  }

  defaultColumnWidth = 150;

  configuratorRequested = new Subject<string>();
  gridConfigUpdated = new Subject<string>();

  constructor(private config: ConfigStateService) {}

  showConfigurator(gridId: string): void {
    this.configuratorRequested.next(gridId);
  }

  getDefaultGridConfig(gridId: string): GridConfig {
    const defaultGridConfig = this._defaultGridConfigs.find((c) => c.id === gridId);

    return defaultGridConfig
      ? JSON.parse(JSON.stringify(defaultGridConfig))
      : { id: gridId, columns: [] };
  }

  getGridConfig(gridId: string): GridConfig {
    const gridConfig =
      this._gridConfigs.find((c) => c.id === gridId) ?? this.getDefaultGridConfig(gridId);

    gridConfig.columns.forEach((c) => (c.width = c.width ?? this.defaultColumnWidth));

    return gridConfig;
  }

  resetGridConfig(gridId: string): void {
    const defaultGridConfig = this.getDefaultGridConfig(gridId);

    this.saveGridConfig(defaultGridConfig);
  }

  saveVisibleColumns(gridId: string, visibleColumnNames: string[]): void {
    const gridConfig = this.getGridConfig(gridId);
    const visibleColumns = visibleColumnNames
      .map((name) => gridConfig.columns.find((c) => c.name === name))
      .filter((c) => c);
    const invisibleColumns = gridConfig.columns.filter((c) => !visibleColumns.includes(c));

    gridConfig.columns = [...visibleColumns, ...invisibleColumns];
    gridConfig.columns.forEach((c) => (c.visible = visibleColumnNames.includes(c.name)));

    this.saveGridConfig(gridConfig);
  }

  updateColumnWidth(gridId: string, name: string, newValue: number) {
    const gridConfig = this.getGridConfig(gridId);

    gridConfig.columns.find((c) => c.name === name).width = newValue;

    this.saveGridConfig(gridConfig);
  }

  private saveGridConfig(gridConfig: GridConfig) {
    const gridConfigs = this._gridConfigs;
    const existingGridConfig = gridConfigs.find((c) => c.id === gridConfig.id);

    if (existingGridConfig) {
      existingGridConfig.columns = gridConfig.columns;
    } else {
      gridConfigs.push(gridConfig);
    }

    const gridConfigsStr = JSON.stringify(gridConfigs);
    localStorage.setItem(this._storageKey, gridConfigsStr);

    this.gridConfigUpdated.next(gridConfig.id);
  }
}
