import { LocalizationService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { TreeviewItem, TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
  private internalSelectedItem: TreeviewItem;

  constructor(private localizationService: LocalizationService) {
    super();
  }

  set selectedItem(value: TreeviewItem) {
    this.internalSelectedItem = value;
  }

  get selectedItem(): TreeviewItem {
    return this.internalSelectedItem;
  }

  // eslint-disable-next-line no-unused-vars
  getText(_selection: TreeviewSelection): string {
    return this.internalSelectedItem
      ? this.localizationService.instant('::acitivityContext') + this.internalSelectedItem.value
      : this.localizationService.instant('::selectElementFromActivity');
  }
}
