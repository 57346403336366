import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortPipe implements PipeTransform {
  transform(array: any[], field?: string): any[] {
    if (field === '' || field === undefined) return;
    return array.sort((a, b) => a[field] - b[field]);
  }
}
