import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { tap } from 'rxjs/operators';
import { CanDeactivateComponent } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(private confirmation: ConfirmationService) {}

  confResult() {
    return new Promise<boolean>((resolve) => {
      this.confirmation
        .warn('::AreYouSureYouWantToCancelEditingWarningMessage', '::AreYouSure', {
          messageLocalizationParams: [],
        })
        .pipe(
          tap((status) => {
            if (status === Confirmation.Status.confirm) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
        )
        .subscribe(() => {});
    });
  }

  async canDeactivate(component: CanDeactivateComponent): Promise<boolean> {
    if (!component.canDeactivate) {
      return await this.confResult();
    }
    return true;
  }
}
