import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidebarMenuItem } from './sidebar-menu.model';

@Component({
  selector: 'cai-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  @Input() menuItems: SidebarMenuItem[];
  @Output() isOpen: EventEmitter<any> = new EventEmitter();
  isCollapsed = false;
  projectId: string;
  projectName: string;
  maxTextLengthToTruncate = 21;

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.isOpen.emit(this.isCollapsed);
  }

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.projectId = params.projectId;
      this.projectName = params.name;
    });
  }

  getTruncatedText(text: string) {
    const index = text.length - 2;
    let prefix = text.substring(0, index);
    const suffix = text.substring(index);

    if (prefix.length > this.maxTextLengthToTruncate) {
      prefix = prefix.substring(0, this.maxTextLengthToTruncate - 3) + '...';
    }

    return prefix + suffix;
  }
}
