export const PROJECT_ROOT_ROUTE_ID = 'Project';
export const PROJECT_ROOT_ROUTE_NAME = '::Menu:Flows';

export const VIRTUAL_AGENT_ROOT_ROUTE_ID = 'VirtualAgent';
export const VIRTUAL_AGENT_ROOT_ROUTE_NAME = '::Menu:VirtualAgent';
export const VIRTUAL_AGENTS_ROOT_ROUTE_NAME = '::Menu:VirtualAgents';

export const RESPONSE_MANAGEMENT_ROUTE_ID = 'ResponseManagement';
export const RESPONSE_MANAGEMENT_ROUTE_NAME = 'Project::ResponseManagement:ResponseEditor';

export const PROMPTS_ROOT_ROUTE_ID = 'Prompts';
export const PROMPTS_ROOT_ROUTE_NAME = 'Project::Prompts';

export const SYSTEM_MESSAGES_ROUTE_ID = 'SystemMessages';
export const SYSTEM_MESSAGES_ROUTE_NAME = 'Project::SystemMessages';

export const CONFIRMATION_ROUTE_ID = 'Confirmation';
export const CONFIRMATION_ROUTE_NAME = 'Project::Confirmation';

export const DASHBOARD_ROUTE_ID = 'Dashboard';
export const DASHBOARD_ROUTE_NAME = '::Menu:Dashboard';

export const INSIGHTS_ROOT_ROUTE_ID = 'Insights';
export const INSIGHTS_ROOT_ROUTE_NAME = 'Project::Insights';

export const CONVERSATIONS_ROUTE_ID = 'Conversations';
export const CONVERSATIONS_ROUTE_NAME = '::Menu:Conversations';

export const GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_ID = 'GenerativeQnaReporting';
export const GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_ROUTE_NAME =
  '::Menu:GenerativeQnaReporting';

export const PREMIUM_REPORTING_ROUTE_NAME = 'PremiumReporting::Menu:PremiumReports';

export const SUBFLOW_TYPE_NAME = 'SubFlow';
export const CREATE_SUBFLOW_ROUTE_ID = 'Create' + SUBFLOW_TYPE_NAME;
export const CREATE_SUBFLOW_ROUTE_NAME = '+ ' + SUBFLOW_TYPE_NAME;

export const PROJECTS_PATH = 'projects';
export const VERSIONS_PATH = 'versions';
export const SCENARIO_DESIGN_PATH = 'scenario-design';
export const VIRTUAL_AGENTS_PATH = 'virtual-agents';
export const INTENTS_PATH = 'intents';
export const EDIT_PATH = 'edit';
export const ADD_PATH = 'add';
export const DASHBOARD_PATH = 'dashboard';
export const CONVERSATIONS_PATH = 'conversations';
export const GENERATIVE_QUESTION_ANSWERING_RECOGNITIONS_PATH =
  'generative-question-answering-recognitions';
export const INSIGHTS_PREFIX_PATH = 'insights';
export const PROMPTS_PREFIX_PATH = 'prompts';
export const RESPONSE_MANAGEMENT_PATH = 'prompts/response-management';
export const SYSTEM_MESSAGES_PATH = 'prompts/system-messages';
export const CONFIRMATION_PATH = 'prompts/confirmation';
export const PREMIUM_REPORTING_PATH = 'premium-reporting';
export const NOTIFICATIONS_PATH = 'notifications';
export const SESSIONS_PATH = 'sessions';
export const CUSTOM_DATA_PATH = 'custom-data';
export const OUTBOUND_RULES_PATH = 'outbound-rules';

export const PROJECT_ROUTE_NAMES = [
  PROJECT_ROOT_ROUTE_NAME,
  VIRTUAL_AGENT_ROOT_ROUTE_NAME,
  VIRTUAL_AGENTS_ROOT_ROUTE_NAME,
  DASHBOARD_ROUTE_NAME,
  CONVERSATIONS_ROUTE_NAME,
  RESPONSE_MANAGEMENT_ROUTE_NAME,
  CONFIRMATION_ROUTE_NAME,
];

export const PROJECT_AND_PREMIUM_REPORTING_ROUTE_NAMES = [
  ...PROJECT_ROUTE_NAMES,
  PREMIUM_REPORTING_ROUTE_NAME,
];
