import { Component, Input } from '@angular/core';

@Component({
  selector: 'cai-nodata',
  templateUrl: './nodata.component.html',
})
export class NoDataComponent {
  @Input() text = true;
  @Input() textPosition = 'text-start';
  @Input() content;
  @Input() image = true;
}
