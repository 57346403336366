<div class="sidebar-menu full-height" [ngClass]="{ 'open-menu': isCollapsed }">
  <h4 (click)="toggle()">
    <i class="fa" [ngClass]="{ 'fa-caret-left': isCollapsed, 'fa-caret-right': !isCollapsed }"></i>
    {{
      projectId
        ? ('Project::EditProject' | abpLocalization)
        : ('Project::NewProject' | abpLocalization)
    }}
  </h4>

  <div class="menu-wrapper">
    <ul>
      <li *ngFor="let item of menuItems">
        <a
          [routerLink]="item.url ? item.url : undefined"
          routerLinkActive="active-link"
          queryParamsHandling="merge"
        >
          <i class="{{ item.icon }}"></i>
          <span class="text-break">{{ item.name }}</span>
        </a>
        <ul *ngIf="item.children">
          <li *ngFor="let innerItem of item.children">
            <a
              [routerLink]="innerItem.url ? innerItem.url : undefined"
              routerLinkActive="active-link"
              [class.active-link]="innerItem.isActive"
              [ngbTooltip]="innerItem.isTooltipEnabled ? innerItem.name : null"
              queryParamsHandling="merge"
            >
              <div class="d-flex align-items-center">
                <div class="bullet-circle me-3"></div>
                <div class="text-nowrap">{{ getTruncatedText(innerItem.name) }}</div>
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
