import { AfterContentInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[caiAfterIf]' })
export class AfterIfDirective implements AfterContentInit {
  @Output('caiAfterIf')
  public after: EventEmitter<void> = new EventEmitter<void>();

  public ngAfterContentInit(): void {
    // timeout helps prevent unexpected change errors
    setTimeout(() => this.after.next());
  }
}
