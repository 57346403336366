import {
  ContentProjectionService,
  LocalizationParam,
  PROJECTION_STRATEGY,
  Strict,
} from '@abp/ng.core';
import { ToastContainerComponent, Toaster } from '@abp/ng.theme.shared';
import { ComponentRef, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TOASTER_LIFE } from '../shared.consts';

@Injectable({
  providedIn: 'root',
})
export class CustomToasterService implements ToasterContract {
  private toasts$ = new ReplaySubject<Toaster.Toast[]>(1);

  private lastId = -1;

  private toasts = [] as Toaster.Toast[];

  private containerComponentRef!: ComponentRef<ToastContainerComponent>;

  constructor(private contentProjectionService: ContentProjectionService) {}

  private setContainer() {
    this.containerComponentRef = this.contentProjectionService.projectContent(
      PROJECTION_STRATEGY.AppendComponentToBody(ToastContainerComponent, {
        toasts$: this.toasts$,
        remove: this.remove,
      })
    );

    this.containerComponentRef.changeDetectorRef.detectChanges();
  }

  info(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): Toaster.ToasterId {
    options.life = TOASTER_LIFE;
    return this.show(message, title, 'info', options);
  }

  success(
    message: LocalizationParam,
    title?: LocalizationParam,
    options = {} as Partial<Toaster.ToastOptions>
  ): Toaster.ToasterId {
    options.life = TOASTER_LIFE;
    return this.show(message, title, 'success', options);
  }

  warn(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ): Toaster.ToasterId {
    return this.show(message, title, 'warning', options);
  }

  error(
    message: LocalizationParam,
    title?: LocalizationParam,
    options = {} as Partial<Toaster.ToastOptions>
  ): Toaster.ToasterId {
    options.sticky = true;
    return this.show(message, title, 'error', options);
  }

  show(
    message: LocalizationParam,
    title: LocalizationParam | undefined = undefined,
    severity: Toaster.Severity = 'neutral',
    options = {} as Partial<Toaster.ToastOptions>
  ): Toaster.ToasterId {
    if (!this.containerComponentRef) this.setContainer();

    const id = ++this.lastId;
    this.toasts.push({
      message,
      title,
      severity,
      options: { closable: true, id, ...options },
    });
    this.toasts$.next(this.toasts);
    return id;
  }

  remove = (id: number) => {
    this.toasts = this.toasts.filter((toast) => toast.options?.id !== id);
    this.toasts$.next(this.toasts);
  };

  clear(containerKey?: string): void {
    this.toasts = !containerKey
      ? []
      : this.toasts.filter((toast) => toast.options?.containerKey !== containerKey);
    this.toasts$.next(this.toasts);
  }
}

export type ToasterContract = Strict<CustomToasterService, Toaster.Service>;
