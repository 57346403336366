import { FormControl, ValidatorFn } from '@angular/forms';
import { ColumnSelectionItem } from 'src/app/shared/column-configurator/models/column-configurator.model';

export class ColumnSelectionValidator {
  static validColumnRequired: ValidatorFn = (fc: FormControl) => {
    const value = fc.value as ColumnSelectionItem;

    if (!value || !value.item.name || value.item.name.length <= 0) {
      return { required: true };
    }

    return null;
  };
}
