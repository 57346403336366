import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  OutboundConversationSettingCreateOrUpdateDto,
  OutboundConversationSettingDto,
} from './outbound-conversation-settings.model';
const PATH = '/api/app/outbound-conversation-settings';

@Injectable({
  providedIn: 'root',
})
export class OutboundConversationSettingsService {
  apiName = 'Default';

  get = () =>
    this.restService.request<void, OutboundConversationSettingDto>(
      {
        method: 'GET',
        url: `${PATH}`,
      },
      { apiName: this.apiName }
    );

  createOrUpdate = (input: OutboundConversationSettingCreateOrUpdateDto) =>
    this.restService.request<
      OutboundConversationSettingCreateOrUpdateDto,
      OutboundConversationSettingDto
    >(
      {
        method: 'POST',
        url: `${PATH}/create-or-update`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
