import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateWords',
})
export class TruncateWordsPipe implements PipeTransform {
  transform(value: string, wordLimit: number): { truncatedContent: string; isTruncated: boolean } {
    if (!value) {
      return { truncatedContent: '', isTruncated: false };
    }

    const words = value.split(' ');

    if (words.length <= wordLimit) {
      return { truncatedContent: value, isTruncated: false };
    }

    return { truncatedContent: words.slice(0, wordLimit).join(' ') + '...', isTruncated: true };
  }
}
