<div class="position-relative flex-grow-1">
  <div class="cai-rich-text-editor" dir="auto">
    <quill-editor
      bounds="self"
      [required]="required"
      [minLength]="minLength"
      [maxLength]="maxLength"
      [formats]="formats"
      [modules]="modules"
      [placeholder]="placeholder"
      [format]="'json'"
      [readOnly]="readOnly"
      [linkPlaceholder]="'http://'"
      [(ngModel)]="content"
      (onContentChanged)="onContentChanged($event)"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      (onEditorCreated)="setFocus($event)"
    >
    </quill-editor>
    <cai-rich-text-error [errors]="ngControl?.control.errors"></cai-rich-text-error>
  </div>
  <i
    *ngIf="isPercentIconVisible"
    ngbTooltip="{{ '::ExpressionInputContextIconTooltip' | abpLocalization }}"
    placement="top"
    #mentionListIcon
    class="fa fa-percent c-pointer add-mention-icon"
    (click)="toggleMentionListModal()"
  >
  </i>
  <cai-mention-list-modal
    *ngIf="showMentionListModal"
    [availableMentions]="availableMentions"
    [mentionListIcon]="mentionListIcon"
    [readOnly]="isMentionReadOnly"
    (addMention)="onAddMention()"
    (editMention)="onEditMention($event)"
    (removeMention)="onRemoveMention($event)"
    (mentionSelected)="onMentionSelected($event)"
    (outsideClicked)="toggleMentionListModal()"
  ></cai-mention-list-modal>
</div>
