import { GetConversationListInput } from './conversation.model';

export interface GetGenerativeQuestionAnsweringRecognitionListDto {
  externalConversationId?: string;
  projectIds?: string[];
  channelNames?: string[];
  startDate?: Date;
  endDate?: Date;
  status?: GenerativeQuestionAnsweringRecognitionStatus;
}

export type GetGenerativeQuestionAnsweringRecognitionsInput = GetConversationListInput;

export interface GenerativeQuestionAnsweringRecognitionListFilterValueModel
  extends GetGenerativeQuestionAnsweringRecognitionListDto {
  conversationId?: string;
}

export interface GenerativeQuestionAnsweringRecognitionDto {
  externalConversationId?: string;
  conversationSessionId?: string;
  question?: string;
  searchThreshold?: number;
  response?: string;
  standAloneQuestion?: string;
  averageConfidence: number;
  fullDuration: number;
  date?: Date;
  knovvuChannelId?: string;
  channelName?: string;
  messageId?: string;
  status?: GenerativeQuestionAnsweringRecognitionStatus;
}

export enum GenerativeQuestionAnsweringRecognitionStatus {
  found,
  notFound,
}
