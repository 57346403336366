import { ActivityModel } from 'src/app/shared/chat/chat.model';

export const RATING_OPTIONS = [1, 2, 3, 4, 5];

export const EmptyTextActivity: ActivityModel = {
  text: '',
  type: '',
  entities: [],
  attachments: [],
  channelData: {
    CustomActionData: '',
  },
};

export const EmptyQuickReplyActivity: ActivityModel = {
  text: '',
  type: '',
  entities: [],
  attachments: [
    {
      contentType: '',
      content: '{"title":null,"subtitle":null,"text":"","images":null,"buttons":[],"tap":null}',
      name: '',
      contentUrl: '',
    },
  ],
  channelData: {
    CustomActionData: '',
  },
};
