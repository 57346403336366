import { LocalizationService } from '@abp/ng.core';
import { Confirmation, ToasterService } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import {
  FLOW_VERSION_CANNOT_BE_UPDATED_ERROR_CODE,
  NLU_INTEGRATION_ERROR_CODE,
} from '../shared.consts';
import { CustomConfirmationService } from '../confirmation/custom-confirmation.service';
export const handleHttpErrors = (injector: Injector, httpError: HttpErrorResponse) => {
  const toasterService = injector.get(ToasterService);
  let errorMessage = httpError.error.error.message;
  if (httpError.status === 403 && httpError.error.error.code.includes('Raven')) {
    const confirmationService = injector.get(CustomConfirmationService);
    const localizationService = injector.get(LocalizationService);

    const options: Partial<Confirmation.Options> = {
      hideYesBtn: true,
      icon: 'fa fa-circle-exclamation',
    };
    switch (httpError.error.error.code) {
      case FLOW_VERSION_CANNOT_BE_UPDATED_ERROR_CODE:
        return;
      case NLU_INTEGRATION_ERROR_CODE:
        confirmationService.warn(
          errorMessage,
          localizationService.instant('::Warning'),
          options,
          false
        );
        return;
    }
  } else if (httpError.status === 400) {
    errorMessage = httpError.error.error.details;
  }
  toasterService.error(errorMessage);
  return;
};
