import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ExportResponseModelDto,
  LocalizedSystemMessagesDto,
  ReplaceAllResponseModelDto,
  ReplaceResponseModelDto,
  ResponseManagementFilterOptionsDto,
  ResponseModelUpdateDto,
  ResponseSearchFilterModelDto,
  ResponseSearchResultItemDto,
  SystemMessageValuesDto,
} from './prompts.model';

@Injectable({
  providedIn: 'root',
})
export class PromptsService {
  apiName = 'Default';
  previousResultSelectionRequestedSubject = new Subject<void>();
  nextResultSelectionRequestedSubject = new Subject<void>();
  searchResultsLoadedSubject = new Subject<number>();
  replaceRequestedSubject = new Subject<string>();
  replaceAllRequestedSubject = new Subject<string>();
  replaceOperationCompletedSubject = new Subject<number>();
  replaceAllOperationCompletedSubject = new Subject<number>();
  replaceAllOperationFailedSubject = new Subject<void>();

  constructor(private restService: RestService) {}

  getResponseManagementFilterOptions = (projectId: string) =>
    this.restService.request<void, ResponseManagementFilterOptionsDto>(
      {
        method: 'GET',
        url: `/api/app/response-management/filter-options`,
        params: {
          projectId: projectId,
        },
      },
      { apiName: this.apiName }
    );

  searchResponses = (responseSearchFilter: ResponseSearchFilterModelDto) =>
    this.restService.request<ResponseSearchFilterModelDto, ResponseSearchResultItemDto[]>(
      {
        method: 'POST',
        url: `/api/app/response-management/search`,
        body: responseSearchFilter,
      },
      { apiName: this.apiName }
    );

  replaceResponse = (replaceResponseModel: ReplaceResponseModelDto) =>
    this.restService.request<ReplaceResponseModelDto, string>(
      {
        method: 'POST',
        url: `/api/app/response-management/replace`,
        body: replaceResponseModel,
      },
      { apiName: this.apiName }
    );

  replaceAllResponses = (replaceResponseModelList: ReplaceAllResponseModelDto[]) =>
    this.restService.request<ReplaceAllResponseModelDto[], number>(
      {
        method: 'POST',
        url: `/api/app/response-management/replace-all`,
        body: replaceResponseModelList,
      },
      { apiName: this.apiName }
    );

  update = (responseModel: ResponseModelUpdateDto) =>
    this.restService.request<ResponseModelUpdateDto, void>(
      {
        method: 'PUT',
        url: `/api/app/response-management/update`,
        body: responseModel,
      },
      { apiName: this.apiName }
    );

  export = (exportResponseModelList: ExportResponseModelDto[], projectId: string) =>
    this.restService.request<ExportResponseModelDto[], void>(
      {
        method: 'POST',
        url: `/api/app/response-management/export/${projectId}`,
        body: exportResponseModelList,
      },
      { apiName: this.apiName }
    );

  exportAll = (projectId: string) =>
    this.restService.request<ResponseSearchFilterModelDto, void>(
      {
        method: 'POST',
        url: `/api/app/response-management/export-all/${projectId}`,
      },
      { apiName: this.apiName }
    );

  getExportFile = (projectId: string, fileId: string) =>
    this.restService.request<void, File>(
      {
        method: 'GET',
        url: `/api/app/response-management/export-file/${projectId}?fileId=${fileId}`,
        responseType: 'blob' as 'json',
      },
      { apiName: this.apiName, skipHandleError: true }
    );

  getProjectSystemMessages = (projectVersionId: string) =>
    this.restService.request<void, LocalizedSystemMessagesDto>(
      {
        method: 'GET',
        url: `/api/app/system-messages/${projectVersionId}`,
      },
      { apiName: this.apiName }
    );

  updateProjectSystemMessages = (
    projectVersionId: string,
    languageMessages: SystemMessageValuesDto
  ) =>
    this.restService.request<SystemMessageValuesDto, SystemMessageValuesDto>(
      {
        method: 'PUT',
        url: `/api/app/system-messages/${projectVersionId}`,
        body: languageMessages,
      },
      { apiName: this.apiName }
    );
}
