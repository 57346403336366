<div>
  <h2>{{ 'Administration::OutboundConversationSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group">
      <label for="outboundCallEndpoint">{{
        'Administration::OutboundConversationSettings:OutboundCallEndpoint' | abpLocalization
      }}</label>
      <input
        id="outboundCallEndpoint"
        formControlName="outboundCallEndpoint"
        class="form-control"
        type="text"
      />
    </div>
  </div>

  <abp-button (click)="submitForm()" iconClass="fa fa-check" [loading]="isLoading">
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
