import { NgModule } from '@angular/core';
import { CoreModule } from '@abp/ng.core';

import { ProjectPublishedNotificationComponent } from './projects/project-published-notification/project-published-notification.component';
import { VirtualAgentTrainedNotificationComponent } from './projects/virtual-agent-trained-notification/virtual-agent-trained-notification.component';
import { ConversationListExcelExportedNotificationComponent } from './conversations/conversation-list-excel-exported-notification/conversation-list-excel-exported-notification.component';
import { ConversationListExcelExportFailedNotificationComponent } from './conversations/conversation-list-excel-export-failed-notification/conversation-list-excel-export-failed-notification.component';
import { DashboardTopIntentsExportedNotificationComponent } from './dashboard/top-intents-excel-exported-notification/top-intents-excel-exported-notification.component';
import { ProjectExportedNotificationComponent } from './projects/project-exported-notification/project-exported-notification.component';
import { ProjectImportedNotificationComponent } from './projects/project-imported-notification/project-imported-notification.component';
import { ProjectExportFailedNotificationComponent } from './projects/project-export-failed-notification/project-export-failed-notification.component';
import { ProjectImportFailedNotificationComponent } from './projects/project-import-failed-notification/project-import-failed-notification.component';
import { ProjectGenerativeQnaTrainedNotificationComponent } from './projects/project-generative-qna-trained-notification/project-generative-qna-trained-notification.component';
import { ProjectGenerativeQnaTrainFailedNotificationComponent } from './projects/project-generative-qna-train-failed-notification/project-generative-qna-train-failed-notification.component';
import { ResponseManagementExportedNotificationComponent } from './projects/response-management-exported-notification/response-management-exported-notification.component';
import { ResponseManagementExportFailedNotificationComponent } from './projects/response-management-export-failed-notification/response-management-export-failed-notification.component';

@NgModule({
  declarations: [
    ProjectPublishedNotificationComponent,
    VirtualAgentTrainedNotificationComponent,
    ConversationListExcelExportedNotificationComponent,
    DashboardTopIntentsExportedNotificationComponent,
    ConversationListExcelExportFailedNotificationComponent,
    ProjectExportedNotificationComponent,
    ProjectImportedNotificationComponent,
    ProjectExportFailedNotificationComponent,
    ProjectImportFailedNotificationComponent,
    ProjectGenerativeQnaTrainedNotificationComponent,
    ProjectGenerativeQnaTrainFailedNotificationComponent,
    ResponseManagementExportedNotificationComponent,
    ResponseManagementExportFailedNotificationComponent,
  ],
  imports: [CoreModule],
  bootstrap: [ProjectPublishedNotificationComponent],
})
export class AppNotificationModule {}
