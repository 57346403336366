import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterExactMatch',
})
export class FilterExactMatchPipe implements PipeTransform {
  transform(items: any[], filter: string, field?: string): any[] {
    if (!items || !filter) {
      return items;
    }
    if (field) {
      return items.filter((item) => item[field] === filter);
    }
    return items.filter((item) => item === filter);
  }
}
