<div class="filter-container">
  <form [formGroup]="form" class="filter-form">
    <div>
      <nav class="navbar">
        <div class="title ms-3">
          <span class="h4">
            <i class="bi bi-filter-circle ms-2 mb-1 align-middle fa-lg"></i>
            {{ '::Title:Filters' | abpLocalization }}
          </span>
        </div>
      </nav>
    </div>
    <div class="filter-panel-accordion d-flex flex-column justify-content-between">
      <div>
        <ngb-accordion
          #accordion="ngbAccordion"
          activeIds="filterConversationId, filterCustomerInformation, filterVirtualAgent, filterChannel, filterRating, filterSentiment, filterDate, filterIntent, filterGenerativeQuestionAnsweringRecognitionStatus"
        >
          <ngb-panel id="filterConversationId" *ngIf="filterOptions.filterConversationId">
            <ng-template ngbPanelHeader let-opened="opened"> </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group group-control">
                <input
                  autocomplete="off"
                  formControlName="filterConversationId"
                  type="text"
                  class="form-control form-height-25 custom-input border-0"
                  [placeholder]="
                    'Conversation::ConversationSessionIdInputPlaceholder' | abpLocalization
                  "
                  (keydown)="onKeydown($event)"
                />
                <span
                  class="input-group-search bg-white ps-1 pe-2 c-pointer"
                  (click)="onSubmitFilters()"
                >
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterCustomerInformation" *ngIf="filterOptions.filterCustomerInformation">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:CustomerInformation' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group group-control">
                <ng-select
                  formControlName="filterCustomerInformationType"
                  [searchable]="false"
                  class="ng-select-focus-disabled customer-information-select"
                >
                  <ng-option *ngFor="let type of customerInformationTypes" [value]="type.key">
                    {{ 'Conversation::CustomerInformationType:' + type.value | abpLocalization }}
                  </ng-option>
                </ng-select>
                <input
                  autocomplete="off"
                  formControlName="filterCustomerInformation"
                  type="text"
                  class="form-control form-height-25 custom-input border-0 m-0"
                  (keydown)="onKeydown($event)"
                />
                <span
                  class="input-group-search bg-white ps-1 pe-2 c-pointer"
                  (click)="onSubmitFilters()"
                >
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterVirtualAgent" *ngIf="filterOptions.filterVirtualAgent">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:VirtualAgent' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterVirtualAgent"
                [multiple]="true"
                [closeOnSelect]="false"
              >
                <ng-option *ngFor="let item of virtualAgents" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterChannel" *ngIf="filterOptions.filterChannel">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:Channel' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterChannel"
                [multiple]="true"
                [closeOnSelect]="false"
              >
                <ng-option *ngFor="let item of channels" [value]="item">
                  {{ 'Project::' + item | abpLocalization }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterRating" *ngIf="filterOptions.filterRating">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:Rating' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterRating"
                [multiple]="true"
                [closeOnSelect]="false"
              >
                <ng-option *ngFor="let item of ratingOptions" [value]="item">
                  {{ item }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterSentiment" *ngIf="filterOptions.filterSentiment">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:Sentiment' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterSentiment"
                [multiple]="true"
                [closeOnSelect]="false"
              >
                <ng-option *ngFor="let item of sentimentOptions; let i = index" [value]="i">
                  {{ 'Dashboard::Widget:Sentiment:' + item | abpLocalization }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterIntent" *ngIf="filterOptions.filterIntent">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:MatchedIntent' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterIntent"
                [multiple]="true"
                [closeOnSelect]="false"
                [loading]="isFilterIntentLoading"
              >
                <ng-option *ngFor="let item of allIntents" [value]="item.intentId">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel
            id="filterGenerativeQuestionAnsweringRecognitionStatus"
            *ngIf="filterOptions.filterGenerativeQuestionAnsweringRecognitionStatus"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{
                    'GenerativeQuestionAnsweringRecognition::RecognitionStatus' | abpLocalization
                  }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="filterGenerativeQuestionAnsweringRecognitionStatus"
                [multiple]="false"
                [closeOnSelect]="true"
              >
                <ng-option
                  *ngFor="let item of generativeQuestionAnsweringRecognitionStatuses"
                  [value]="item"
                >
                  {{
                    'GenerativeQuestionAnsweringRecognition::RecognitionStatus:' + item
                      | abpLocalization
                  }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterDate" *ngIf="filterOptions.filterDate">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">{{ '::Title:Date' | abpLocalization }}</h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <cai-date-range-selection-panel
                #dateTimeRangePicker
                [config]="dateSelectionConfiguration"
                [value]="dateSelectionValue"
                (onChangeOut)="onDateRangeChanges($event)"
              >
              </cai-date-range-selection-panel>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="filter-action-buttons">
        <button class="btn clear-button w-50 me-2" (click)="onClearFilters()">
          {{ '::Button:Clear' | abpLocalization }}
        </button>
        <button
          placement="top"
          class="btn submit-button w-50 ms-2"
          (click)="onSubmitFilters()"
          [disabled]="showLoader"
        >
          <i class="far fa-filter ms-2 me-2"></i>
          <span>{{ '::Button:Filter' | abpLocalization }}</span>
          <i *ngIf="showLoader" class="fa fa-spinner fa-pulse fa-fw ms-1"></i>
        </button>
      </div>
    </div>
  </form>
</div>
