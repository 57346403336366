<!-- <div class="row">
  <div *ngIf="versionInfo.version && versionInfo.updateDate" class="col-md-12 float-left">
    {{ '::CurrentCAIVersion' | abpLocalization }} : {{ versionInfo.version }} ({{
      versionInfo.updateDate
    }})
  </div>
  <div class="col-md-12 float-left">
    {{ '::CurrentUIVersion' | abpLocalization }} : {{ versionInfo.uiVersion }} ({{
      versionInfo.uiUpdateDate
    }})
  </div>
</div> -->

<div class="row">
  <div class="col-md-12 float-left">
    <b>{{ '::ProductVersion' | abpLocalization }}:</b>
    <span>
      <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw ms-1"></i> {{ productVersion }}
    </span>
  </div>
</div>

<br />

<p (click)="toggleDetails()" style="text-decoration: underline; cursor: pointer">
  {{ (isDetailsVisible ? '::HideDetails' : '::ShowDetails') | abpLocalization }}
</p>

<div *ngIf="isDetailsVisible">
  <div *ngFor="let pod of serviceVersionsDto?.appSetDto?.pods" class="row">
    <div class="col-md-12 float-left">
      <b>{{ pod?.app }}:</b> {{ pod?.tag }}
    </div>
  </div>

  <br />

  <div class="row">
    <div class="col-md-12 float-left">
      <b>{{ '::MLProvider' | abpLocalization }}:</b>
      {{ serviceVersionsDto?.nluProviderDto?.nluProvider }}
    </div>
    <div
      *ngIf="serviceVersionsDto?.nluProviderDto?.nluClientBaseAddress !== ''"
      class="col-md-12 float-left"
    >
      <b>{{ '::MLUrl' | abpLocalization }}:</b>
      {{ serviceVersionsDto?.nluProviderDto?.nluClientBaseAddress }}
    </div>
  </div>
</div>
