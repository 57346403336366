import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cai-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
})
export class SearchListComponent {
  private _value: any;

  @Input() divClass = '';
  @Input() placeholder = this.localizationService.instant('::SearchName');
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  constructor(private localizationService: LocalizationService) {}
  get value(): any {
    return this._value;
  }

  @Input()
  set value(value: any) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this.valueChange.emit(this._value);
  }

  @Output()
  valueChange = new EventEmitter<any>();

  changeFieldValue(event) {
    this.changeEvent.emit(event);
  }

  clearSearch() {
    this.value = '';
    this.changeEvent.emit(this.value);
  }
}
