import { Directive, AfterContentChecked } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

//https://github.com/swimlane/ngx-datatable/issues/923#issuecomment-927875594
@Directive({ selector: '[caiNgxDatatableResizer]' })
export class NgxDatatableResizerDirective implements AfterContentChecked {
  constructor(private table: DatatableComponent) {}

  ngAfterContentChecked(): void {
    setTimeout(() => {
      if (this.table && this.table.element.clientWidth !== this.table._innerWidth) {
        window.dispatchEvent(new Event('resize'));
      }
    }, 0);
  }
}
