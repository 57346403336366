<div class="lp-sidebar-wrapper">
  <nav role="navigation" class="lp-sidebar-navi">
    <ul>
      <li *ngFor="let component of contentBefore" class="position-relative">
        <ng-container *ngComponentOutlet="component; injector: injector"></ng-container>
      </li>

      <ng-container
        *ngFor="let route of routes$ | async; trackBy: trackByFn"
        [ngTemplateOutlet]="
          isDropdown(route) ? dropdownLink : isAction(route) ? actionLink : defaultLink
        "
        [ngTemplateOutletContext]="{ $implicit: route, level: initialLevel }"
      >
      </ng-container>

      <li *ngFor="let component of contentAfter" class="position-relative">
        <ng-container *ngComponentOutlet="component; injector: injector"></ng-container>
      </li>

      <ng-template #defaultLink let-route let-level="level">
        <li
          routerLinkActive="active-page current"
          [routerLinkActiveOptions]="{ exact: route.path === '/' }"
          [routerLink]="[route.path]"
          *abpPermission="route.requiredPolicy"
          (click)="$event.stopPropagation(); onNavigate(route, level)"
        >
          <a class="d-flex justify-content-between">
            <div (click)="clickedToLink.emit()">
              <ng-container
                *ngTemplateOutlet="linkContent; context: { $implicit: route }"
              ></ng-container>
            </div>

            <ng-container
              *ngTemplateOutlet="actionsContent; context: { $implicit: route }"
            ></ng-container>
          </a>
        </li>
      </ng-template>

      <ng-template #actionLink let-route let-level="level">
        <li *abpPermission="route.requiredPolicy" (click)="$event.stopPropagation()">
          <a
            (click)="$event.stopPropagation(); route.onClick && route.onClick()"
            class="d-flex justify-content-between"
          >
            <div>
              <ng-container
                *ngTemplateOutlet="linkContent; context: { $implicit: route }"
              ></ng-container>
            </div>

            <ng-container
              *ngTemplateOutlet="actionsContent; context: { $implicit: route }"
            ></ng-container>
          </a>
        </li>
      </ng-template>

      <ng-template #linkContent let-route>
        <span *ngIf="route.iconClass" class="lp-icon">
          <i [ngClass]="route.iconClass"></i>
        </span>
        <span class="lp-text">
          {{ route.name | abpLocalization }}
        </span>
      </ng-template>

      <ng-template #actionsContent let-route>
        <div *ngIf="route.actions?.length" class="d-flex gap-2">
          <div *ngFor="let action of route.actions">
            <small
              (click)="$event.stopPropagation(); action.onClick()"
              *abpPermission="action.requiredPolicy"
              title="{{ action.name | abpLocalization }}"
            >
              <i [ngClass]="action.iconClass"></i>
            </small>
          </div>
        </div>
      </ng-template>

      <ng-template #dropdownLink let-route let-level="level">
        <ng-container *ngIf="route.children?.length">
          <li
            *abpPermission="route.requiredPolicy"
            class="has-drop"
            [class.current]="expandedRoutes.has(route.name + level)"
          >
            <a
              attr.data-level="{{ level }}"
              href="javascript:void(0)"
              (click)="
                $event.stopPropagation();
                isMenuPlacementTop && !smallScreen ? null : toggleExpand(route, level)
              "
            >
              <ng-container
                *ngTemplateOutlet="linkContent; context: { $implicit: route }"
              ></ng-container>

              <span class="lp-arrow-icon" [attr.for]="route.name">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </a>
            <ul
              class="dropdown-ul"
              [ngClass]="{
                'd-block overflow-hidden': isMenuPlacementTop && !smallScreen ? false : true
              }"
              [id]="route.name"
            >
              <div
                #routeContainer
                [@collapse]="
                  !isMenuPlacementTop
                    ? expandedRoutes.has(route.name + level)
                      ? 'expanded'
                      : 'collapsed'
                    : ''
                "
              >
                <ng-container
                  *ngFor="let child of route.children; trackBy: trackByFn"
                  [ngTemplateOutlet]="dropdownMenu"
                  [ngTemplateOutletContext]="{ $implicit: child, level: level + 1 }"
                ></ng-container>
              </div>
            </ul>
          </li>
        </ng-container>
      </ng-template>

      <ng-template #dropdownMenu let-route let-level="level">
        <ng-container
          *ngTemplateOutlet="
            isDropdown(route) ? dropdownLink : isAction(route) ? actionLink : defaultLink;
            context: { $implicit: route, level: level }
          "
        ></ng-container>
      </ng-template>
    </ul>
  </nav>
</div>

<!-- EDIT SUB-FLOW MODAL -->
<abp-modal [(visible)]="isEditSubFlowModalOpen">
  <ng-template #abpHeader>
    <h3>{{ 'Project::EditSubFlow' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="subFlowForm" (ngSubmit)="updateSubFlow()">
      <div class="mt-2 fade-in-top">
        <div class="form-group">
          <label for="current1">{{ '::Name' | abpLocalization }}:</label>
          <input type="text" class="form-control" formControlName="name" autofocus />
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button
      [disabled]="isSubFlowSavingInProgress"
      type="button"
      class="btn btn-outline-primary"
      (click)="closeSubFlowModal()"
    >
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      [loading]="isSubFlowSavingInProgress"
      (click)="updateSubFlow()"
      [disabled]="subFlowForm?.invalid"
    >
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
<!-- END OF EDIT SUB-FLOW MODAL -->
