import { ConfigStateService, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { CustomSettingsDto } from './custom-settings.model';
const PATH = 'api/app/custom-settings/settings';

@Injectable({
  providedIn: 'root',
})
export class CustomSettingsService {
  apiName = 'Default';

  save = (settings: CustomSettingsDto[]) =>
    this.restService
      .request<CustomSettingsDto[], void>(
        {
          method: 'POST',
          url: `${PATH}`,
          body: settings,
        },
        { apiName: this.apiName }
      )
      .pipe(switchMap(() => this.configStateService.refreshAppState()));

  constructor(private restService: RestService, private configStateService: ConfigStateService) {}
}
