import { LocalizationService } from '@abp/ng.core';
import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'cai-rich-text-error',
  templateUrl: './rich-text-error.component.html',
  styleUrls: ['./rich-text-error.component.scss'],
})
export class RichTextErrorComponent {
  @Input() set errors(errors: ValidationErrors | null) {
    this.checkErrors(errors);
  }

  showErrors = false;
  errorMessage = '';

  constructor(private localizationService: LocalizationService) {}

  checkErrors(errors: ValidationErrors | null) {
    this.showErrors = false;
    this.errorMessage = '';

    if (errors) {
      for (const errorKey of Object.keys(errors)) {
        const error = errors[errorKey];

        if (errorKey === 'maxLengthError') {
          this.showErrors = true;
          this.errorMessage = this.localizationService.instant(
            { key: '::SelectedInputMaxLengthValue', defaultValue: '' },
            error.maxLength
          );
        } else if (errorKey === 'minLengthError') {
          this.showErrors = true;
          this.errorMessage = this.localizationService.instant(
            { key: '::SelectedInputMinLengthValue', defaultValue: '' },
            error.minLength
          );
        }
      }
    }
  }
}
