import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MentionModel } from '../../proxy/rich-text-editor.model';

@Component({
  selector: 'cai-mention-list-modal',
  templateUrl: './mention-list-modal.component.html',
  styleUrls: ['./mention-list-modal.component.scss'],
})
export class MentionListModalComponent {
  _availableMentions: MentionModel[] = [];
  @Input() set availableMentions(availableMentions: MentionModel[]) {
    this._availableMentions = availableMentions;
    this.setFilteredMentions();
  }
  get availableMentions() {
    return this._availableMentions;
  }
  @Input() mentionListIcon: ElementRef;
  @Input() readOnly = false;

  @Output() addMention = new EventEmitter();
  @Output() editMention = new EventEmitter<MentionModel>();
  @Output() removeMention = new EventEmitter<MentionModel>();
  @Output() mentionSelected = new EventEmitter<MentionModel>();
  @Output() outsideClicked = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as Node;

    if (
      !this.eRef.nativeElement.contains(target) &&
      this.mentionListIcon &&
      !this.mentionListIcon.nativeElement.contains(target)
    ) {
      this.outsideClicked.emit();
    }
  }

  searchTerm;
  filteredMentions: MentionModel[] = [];

  constructor(private eRef: ElementRef) {}

  setFilteredMentions() {
    this.filteredMentions = this.availableMentions.filter(
      (mention) =>
        !this.searchTerm || mention.key.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
    );
  }

  onAddMention() {
    this.addMention.emit();
    this.outsideClicked.emit();
  }

  onEditMention(mention: MentionModel) {
    this.editMention.emit(mention);
    this.outsideClicked.emit();
  }

  onRemoveMention(mention: MentionModel) {
    this.removeMention.emit(mention);
    this.outsideClicked.emit();
  }

  onMentionSelected(mention: MentionModel) {
    this.mentionSelected.emit(mention);
    this.outsideClicked.emit();
  }
}
