import {
  ConfigStateService,
  getShortDateFormat,
  getShortDateShortTimeFormat,
  getShortTimeFormat,
} from '@abp/ng.core';
import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateDisplayType } from 'src/app/shared/enums/date-display-type';

@Pipe({
  name: 'dateFormatter',
})
@Injectable({
  providedIn: 'root',
})
export class DateFormatterPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, private config: ConfigStateService) {}

  transform(
    value: any,
    dateDisplayType: DateDisplayType = DateDisplayType.shortDateShortTime,
    timeZone?: string
  ): string {
    if (value !== null) {
      let format: string;

      const longTimeFormat = this.config.getDeep(
        'localization.currentCulture.dateTimeFormat.longTimePattern'
      );

      switch (dateDisplayType) {
        case DateDisplayType.shortDate:
          format = getShortDateFormat(this.config);
          break;
        case DateDisplayType.shortTime:
          format = getShortTimeFormat(this.config);
          break;
        case DateDisplayType.shortDateShortTime:
          format = getShortDateShortTimeFormat(this.config);
          break;
        case DateDisplayType.shortDateLongTime:
          format = getShortDateFormat(this.config) + ' ' + longTimeFormat.replace('tt', 'a');
          break;
        default:
          format = getShortDateShortTimeFormat(this.config);
          break;
      }

      if (typeof timeZone !== 'undefined') {
        return formatDate(value, format, this.locale, timeZone);
      } else {
        return formatDate(value, format, this.locale);
      }
    } else {
      return '';
    }
  }
}
