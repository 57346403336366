import { Component, Input } from '@angular/core';

@Component({
  selector: 'cai-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
  @Input() value: boolean;

  constructor() {}
}
