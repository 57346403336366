import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnSelectionItem, GridConfig } from '../../models/column-configurator.model';
import { ColumnConfiguratorService } from '../../services/column-configurator.service';
import { ColumnSelectionValidator } from '../../validators/column-selection.validator';

@Component({
  selector: 'cai-column-configurator',
  templateUrl: './column-configurator.component.html',
  styleUrls: ['./column-configurator.component.scss'],
})
export class ColumnConfiguratorComponent {
  @ViewChild('modal') modal: TemplateRef<any>;
  modalRef: NgbModalRef;
  selectionItems: ColumnSelectionItem[] = [];
  gridConfig: GridConfig;
  form: FormGroup;
  sortPredicateFn: unknown;

  get columnsFormArray(): FormArray {
    return this.form.get('columns') as FormArray;
  }

  get allColumnsSelected(): boolean {
    const selection = this.columnsFormArray.getRawValue() as ColumnSelectionItem[];

    return selection.length === this.selectionItems.length + this.lockedCount;
  }

  get lockedCount(): number {
    return this.gridConfig.columns.filter((x) => x.locked).length;
  }

  constructor(
    private modalService: NgbModal,
    private service: ColumnConfiguratorService,
    private fb: FormBuilder
  ) {
    this.sortPredicateFn = this.sortPredicate.bind(this);

    this.service.configuratorRequested.subscribe((result) => {
      this.show(result);
    });
  }

  show(gridId: string): void {
    this.gridConfig = this.service.getGridConfig(gridId);

    this.buildForm();
    this.setSelectionItems();

    this.modalRef = this.modalService.open(this.modal);
  }

  sortPredicate(index: number): boolean {
    return index >= this.lockedCount;
  }

  onClickAddColumn(): void {
    const columnFormControl = new FormControl(null, [ColumnSelectionValidator.validColumnRequired]);

    this.columnsFormArray.push(columnFormControl, { emitEvent: false });
  }

  onClickRemoveColumn(_: MouseEvent, index: number): void {
    this.columnsFormArray.removeAt(index);
  }

  onClickReset(): void {
    this.service.resetGridConfig(this.gridConfig.id);
    this.modalRef.dismiss();
  }

  onClickSave(): void {
    if (!this.form.valid) {
      return;
    }

    const visibleColumnIds = this.columnsFormArray
      .getRawValue()
      .map((x) => x.item.name) as string[];

    this.service.saveVisibleColumns(this.gridConfig.id, visibleColumnIds);

    this.modalRef.dismiss();
  }

  onDropRow(event: CdkDragDrop<string[]>): void {
    const formControl = this.columnsFormArray.at(event.previousIndex);

    this.columnsFormArray.removeAt(event.previousIndex);
    this.columnsFormArray.insert(event.currentIndex, formControl);
  }

  selectionCompareFn(a: ColumnSelectionItem, b: ColumnSelectionItem): boolean {
    return a.item.name === b.item.name;
  }

  private buildForm(): void {
    const columnFormControls: FormControl<ColumnSelectionItem>[] = [];

    this.gridConfig.columns
      .filter((col) => col.visible)
      .forEach((col) => {
        const selectionItem: ColumnSelectionItem = {
          disabled: false,
          item: col,
        };
        const columnFormControl = new FormControl(selectionItem);

        columnFormControls.push(columnFormControl);
      });

    this.form = this.fb.group({
      columns: this.fb.array(columnFormControls),
    });

    this.form.valueChanges.subscribe(() => {
      this.setSelectionItems();
    });
  }

  private setSelectionItems(): void {
    const selection = this.columnsFormArray.getRawValue() as ColumnSelectionItem[];

    this.selectionItems = this.gridConfig.columns
      .filter((x) => !x.locked)
      .map((x) => {
        return {
          disabled: selection.findIndex((s) => s?.item.name === x.name) > -1,
          item: x,
        };
      });
  }
}
