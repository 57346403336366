import { Component, OnInit } from '@angular/core';
import uiVersionJson from 'src/version.json';
import { VersionInfoService } from './version-info.service';
import { ServiceVersionsDto } from './version-info.model';
import { PRODUCT_VERSION_APP_NAME } from '../shared/shared.consts';

@Component({
  selector: 'cai-version-info',
  templateUrl: './version-info.component.html',
})
export class VersionInfoComponent implements OnInit {
  versionInfo = {
    uiVersion: uiVersionJson.version,
    uiUpdateDate: uiVersionJson.updateDate,
    version: '',
    updateDate: '',
  };

  serviceVersionsDto: ServiceVersionsDto;
  productVersion: string;
  isDetailsVisible = false;
  loading = true;

  constructor(private versionInfoService: VersionInfoService) {}

  ngOnInit() {
    this.versionInfoService.getVersionInfo().subscribe((response) => {
      this.versionInfo.version = response.version;
      this.versionInfo.updateDate = response.updateDate;
      setTimeout(() => {
        this.loading = false;
      }, 10);
    });

    this.versionInfoService.getServiceVersions().subscribe((response) => {
      this.serviceVersionsDto = response;
      this.productVersion = this.serviceVersionsDto?.appSetDto?.pods?.find(
        (x) => x?.app === PRODUCT_VERSION_APP_NAME
      )?.tag;
    });
  }

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }
}
