<div>
  <h2>{{ 'Administration::OpenAiSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group">
      <label for="provider" class="pt-3"
        >{{ 'Administration::OpenAiSettings:Provider' | abpLocalization }} *</label
      >
      <ng-select id="provider" formControlName="provider" (change)="setSelectedProvider()">
        <ng-option *ngFor="let providerOption of providerOptions" [value]="providerOption">
          {{ providerOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label for="apiKey"
        >{{ 'Administration::OpenAiSettings:APIKey' | abpLocalization }}
        <span *ngIf="!hasExistingApiKey">*</span></label
      >
      <input
        id="apiKey"
        formControlName="apiKey"
        class="form-control"
        type="password"
        autocomplete="new-password"
        [placeholder]="
          hasExistingApiKey
            ? ('Administration::OpenAiSettings:APIKeyPlaceHolder' | abpLocalization)
            : ''
        "
      />
    </div>

    <div class="form-group" *ngIf="isOpenAiSelected()">
      <label for="model">{{ 'Administration::OpenAiSettings:Model' | abpLocalization }} *</label>
      <ng-select id="model" formControlName="model">
        <ng-option *ngFor="let modelOption of modelOptions" [value]="modelOption">
          {{ modelOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="deploymentName"
        >{{ 'Administration::OpenAiSettings:DeploymentName' | abpLocalization }} *</label
      >
      <input
        id="deploymentName"
        formControlName="deploymentName"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="resourceName"
        >{{ 'Administration::OpenAiSettings:ResourceName' | abpLocalization }} *</label
      >
      <input id="resourceName" formControlName="resourceName" class="form-control" type="text" />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="embeddingDeployment"
        >{{ 'Administration::OpenAiSettings:EmbeddingDeployment' | abpLocalization }}
        <span *ngIf="isGenerativeQuestionAnsweringFeatureEnabled">*</span></label
      >
      <input
        id="embeddingDeployment"
        formControlName="embeddingDeployment"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group">
      <label for="embeddingName">
        {{
          (isOpenAiSelected()
            ? 'Administration::OpenAiSettings:EmbeddingNameForOpenAi'
            : 'Administration::OpenAiSettings:EmbeddingNameForAzureOpenAi'
          ) | abpLocalization
        }}
        <span *ngIf="isGenerativeQuestionAnsweringFeatureEnabled">*</span>
      </label>
      <ng-select id="embeddingName" formControlName="embeddingName">
        <ng-option
          *ngFor="let embeddingNameOption of embeddingNameOptions"
          [value]="embeddingNameOption"
        >
          {{ embeddingNameOption }}
        </ng-option>
      </ng-select>
    </div>
    <span
      ><b class="mb-2"
        >{{ 'Administration::OpenAiSettings:Advanced:Settings' | abpLocalization }}
        <span class="arrow" (click)="toggleAdvancedSettings()">
          <i [ngClass]="'fas fa-caret-' + (isAdvancedSettingsOpen ? 'up' : 'down')"></i> </span
      ></b>
    </span>
    <div *ngIf="isAdvancedSettingsOpen" class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="maxTokens">
            {{ 'Administration::OpenAiSettings:Advanced:OpenAiMaxTokens' | abpLocalization }}
          </label>
          <input
            type="number"
            id="maxTokens"
            formControlName="maxTokens"
            class="form-control"
            autocomplete="nope"
          />
          <small class="text-muted switch-info-text-size">
            {{
              'Administration::OpenAiSettings:Advanced:OpenAiMaxTokens:Description'
                | abpLocalization
            }}
          </small>
        </div>
        <div class="form-group">
          <label for="chunkSize">
            {{ 'Administration::OpenAiSettings:Advanced:SplitChunkSize' | abpLocalization }}
          </label>
          <input
            type="number"
            id="chunkSize"
            formControlName="chunkSize"
            class="form-control"
            autocomplete="nope"
          />
          <small class="text-muted switch-info-text-size">
            {{
              'Administration::OpenAiSettings:Advanced:SplitChunkSize:Description' | abpLocalization
            }}
          </small>
        </div>
        <div class="form-group">
          <label for="chunkOverlap">
            {{ 'Administration::OpenAiSettings:Advanced:SplitChunkOverlap' | abpLocalization }}
          </label>
          <input
            type="number"
            id="chunkOverlap"
            formControlName="chunkOverlap"
            class="form-control"
            autocomplete="nope"
          />
          <small class="text-muted switch-info-text-size">
            {{
              'Administration::OpenAiSettings:Advanced:SplitChunkOverlap:Description'
                | abpLocalization
            }}
          </small>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="splitCount">
            {{
              'Administration::OpenAiSettings:Advanced:RetrievedDocumentSplitCount'
                | abpLocalization
            }}
          </label>
          <input
            type="number"
            id="splitCount"
            formControlName="splitCount"
            class="form-control"
            autocomplete="nope"
          />
          <small class="text-muted switch-info-text-size">
            {{
              'Administration::OpenAiSettings:Advanced:RetrievedDocumentSplitCount:Description'
                | abpLocalization
            }}
          </small>
        </div>
        <div class="form-group">
          <label for="llmProviderTokenPerMin">
            {{ 'Administration::OpenAiSettings:Advanced:LlmProviderTokenPerMin' | abpLocalization }}
          </label>
          <input
            type="number"
            id="llmProviderTokenPerMin"
            formControlName="llmProviderTokenPerMin"
            class="form-control"
            autocomplete="nope"
          />
          <small class="text-muted switch-info-text-size">
            <span
              [innerHTML]="
                'Administration::OpenAiSettings:Advanced:LlmProviderTokenPerMin:Description'
                  | abpLocalization
              "
            >
            </span>
          </small>
        </div>
        <div class="form-group">
          <label for="crawlerType">{{
            'Administration::OpenAiSettings:Advanced:CrawlerType' | abpLocalization
          }}</label>
          <ng-select id="crawlerType" formControlName="crawlerType">
            <ng-option
              *ngFor="let crawlerTypeOption of crawlerTypeOptions"
              [value]="crawlerTypeOption.value"
            >
              <span [title]="crawlerTypeOption.tooltip">{{ crawlerTypeOption.name }}</span>
            </ng-option>
          </ng-select>
          <small class="text-muted switch-info-text-size">
            {{
              'Administration::OpenAiSettings:Advanced:CrawlerType:Description' | abpLocalization
            }}
          </small>
        </div>
      </div>
    </div>
    <span
      ><b
        ><p class="mt-2">{{ 'Administration::OpenAiSettings:Prompts' | abpLocalization }}</p></b
      ></span
    >
    <div class="form-group" *ngIf="isUtteranceGenerationFeatureEnabled">
      <label for="utteranceGeneration">{{
        'Administration::OpenAiSettings:Prompts:UtteranceGeneration' | abpLocalization
      }}</label>
      <input
        id="utteranceGeneration"
        formControlName="utteranceGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>
    <div class="form-group" *ngIf="isTextResponseGenerationFeatureEnabled">
      <label for="textResponseGeneration">{{
        'Administration::OpenAiSettings:Prompts:TextResponseGeneration' | abpLocalization
      }}</label>
      <input
        id="textResponseGeneration"
        formControlName="textResponseGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isConversationSummarizationFeatureEnabled">
      <label for="conversationSummarization">{{
        'Administration::OpenAiSettings:Prompts:ConversationSummarization' | abpLocalization
      }}</label>
      <input
        id="conversationSummarization"
        formControlName="conversationSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isLiveChatSummarizationFeatureEnabled">
      <label for="liveChatSummarization">{{
        'Administration::OpenAiSettings:LiveChatSummarization' | abpLocalization
      }}</label>
      <input
        id="liveChatSummarization"
        formControlName="liveChatSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isProjectGenerationFeatureEnabled">
      <label for="projectGeneration">{{
        'Administration::OpenAiSettings:Prompts:ProjectGeneration' | abpLocalization
      }}</label>
      <input
        id="projectGeneration"
        formControlName="projectGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>
  </div>
  <abp-button
    (click)="submitForm()"
    iconClass="fa fa-check"
    [loading]="isLoading"
    [disabled]="!isFormValid()"
    class="ms-1"
  >
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
