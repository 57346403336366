export class ObjectHelper {
  static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }

  static isEqual(firstObject: any, secondObject): boolean {
    if (
      JSON.stringify(this.deepSort(firstObject)) === JSON.stringify(this.deepSort(secondObject))
    ) {
      return true;
    } else {
      return false;
    }
  }

  static deepSort(unsortedObj: any) {
    if (unsortedObj != null) {
      const sorted = Object.keys(unsortedObj)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unsortedObj[key];
          if (typeof obj[key] === 'object') {
            obj[key] = this.deepSort(obj[key]);
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach((element) => {
              // eslint-disable-next-line no-unused-vars
              element = this.deepSort(element);
            });
          }
          return obj;
        }, {});
      return sorted;
    }
  }

  // items.sort(compareBy('name'))
  static compareBy = <TProp extends PropertyKey>(prop: TProp) => {
    return (a: { [k in TProp] }, b: { [k in TProp] }) =>
      a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
  };

  static isObjectKeysEqual(object1, object2) {
    const keys1 = Object.keys(object1).sort();
    const keys2 = Object.keys(object2).sort();
    return this.isEqual(keys1, keys2);
  }

  static getEnumValues<E extends EnumObject>(enumObject: E): EnumObjectEnum<E>[] {
    return Object.keys(enumObject)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => enumObject[key] as EnumObjectEnum<E>);
  }

  static getEnumKeyValuePairs<E extends EnumObject>(
    enumObject: E
  ): { key: EnumObjectEnum<E>; value: E[EnumObjectEnum<E>] }[] {
    return this.getEnumValues(enumObject).map((key) => ({ key, value: enumObject[key] }));
  }
}

type EnumObject = { [key: string]: number | string };
type EnumObjectEnum<E extends EnumObject> = E extends { [key: string]: infer ET | string }
  ? ET
  : never;
