<div class="search-inputs overflow-hidden input-group group-control w-25" [ngClass]="divClass">
  <span class="input-group-text bg-white ps-2 pe-2">
    <i class="search-icon fa fa-search"></i>
  </span>
  <input
    type="text"
    class="form-control form-height-25 custom-input border-0 ps-0 pe-0"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="changeFieldValue($event)"
  />
  <span class="input-group-text bg-white ps-2 pe-2" (click)="clearSearch()">
    <i class="clear-icon fa fa-times"></i>
  </span>
</div>
