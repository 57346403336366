import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ConversationListExcelExportedNotificationModel } from '../models/conversation-list-excel-exported-notification-model';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConversationService } from 'src/app/insights/proxy/conversation.service';
import { FileDownloadHelperService } from 'src/app/shared/services/file-download-helper.service';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { DateDisplayType } from 'src/app/shared/enums/date-display-type';

@Component({
  selector: 'cai-conversation-list-excel-exported-notification',
  templateUrl: './conversation-list-excel-exported-notification.component.html',
  styleUrls: ['./conversation-list-excel-exported-notification.component.scss'],
})
@NotificationType('ConversationListExcelExportedNotification')
export class ConversationListExcelExportedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ConversationListExcelExportedNotificationModel;

  constructor(
    private conversationService: ConversationService,
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private fileDownloadHelperService: FileDownloadHelperService,
    private dateFormatterPipe: DateFormatterPipe
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant('Conversation::ListReportIsReady');

    this.toasterService.success(message);
  }

  downloadFile() {
    let nowString = this.dateFormatterPipe.transform(
      new Date(),
      DateDisplayType.shortDateShortTime
    );

    nowString = nowString.replace(/[^a-zA-Z0-9]/g, '_');

    const fileName = `${this.localizationService.instant(
      'Conversation::ListReportFileNamePrefix'
    )}_${nowString}`;

    this.conversationService.getExcelReport(this.notification.fileId).subscribe(
      (result) => {
        this.fileDownloadHelperService.handleDownloadFileResult(result, fileName);
      },
      (error) => {
        this.fileDownloadHelperService.handleDownloadFileError(error);
      }
    );
  }
}
