import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { identityEntityPropContributors } from './entity-prop-contributors';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'projects/:projectId/versions/:projectVersionId/scenario-design',
    loadChildren: () =>
      import('./scenario-designer/scenario-designer.module').then((m) => m.ScenarioDesignerModule),
  },
  {
    path: 'projects/:projectId/virtual-agents',
    loadChildren: () => import('./virtual-agents/virtual-agent.module').then((m) => m.AgentModule),
  },
  {
    path: 'projects/:projectId/prompts',
    loadChildren: () => import('./prompts/prompts.module').then((m) => m.PromptsModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('./integrations/integration.module').then((m) => m.IntegrationModule),
  },
  {
    path: 'projects/:projectId/premium-reporting',
    loadChildren: () =>
      import('@sestek/premium-reporting').then((m) => m.PremiumReportingModule.forLazy()),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('@sestek/notification/management').then((m) => m.NotificationManagementModule),
  },
  {
    path: 'projects/:projectId/notifications',
    loadChildren: () =>
      import('@sestek/notification/management').then((m) => m.NotificationManagementModule),
  },
  {
    path: 'projects/:projectId/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'projects/:projectId/insights',
    loadChildren: () => import('./insights/insights.module').then((m) => m.InsightsModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then((m) => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () =>
      import('@volo/abp.ng.identity').then((m) =>
        m.IdentityModule.forLazy({
          entityPropContributors: identityEntityPropContributors,
        })
      ),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then((m) => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () =>
      import('./saas-extended/saas-extended.module').then((m) => m.SaasExtendedModule),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then((m) => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('./applications-extended/applications-extended.module').then(
        (m) => m.ApplicationsExtendedModule
      ),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then((m) => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then((m) =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy()),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
