import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { ConversationListExcelExportFailedNotificationModel } from '../models/conversation-list-excel-export-failed-notification-model';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'cai-conversation-list-excel-export-failed-notification',
  templateUrl: './conversation-list-excel-export-failed-notification.component.html',
  styleUrls: ['./conversation-list-excel-export-failed-notification.component.scss'],
})
@NotificationType('ConversationListExcelExportFailedNotification')
export class ConversationListExcelExportFailedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: ConversationListExcelExportFailedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant(
      'Conversation::ListReportCreationFailedMessage'
    );

    this.toasterService.error(message);
  }
}
