import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field?: string): any[] {
    if (field === '' || field === undefined) {
      return array.sort((a, b) => a.localeCompare(b));
    } else {
      return array.sort((a, b) => (a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1));
    }
  }
}
