export const PREFIX = 'OpenAi';
export const AZURE_PREFIX = 'Azure.OpenAi';
export const PROVIDER = PREFIX + '.Provider';
export const AZURE_ADVANCED_PREFIX = AZURE_PREFIX + '.Advanced';
export const ADVANCED_PREFIX = PREFIX + '.Advanced';

export const API_KEY = PREFIX + '.ApiKey';
export const HAS_EXISTING_API_KEY = PREFIX + '.HasExistingApiKey';

export const EMBEDDING_NAME = PREFIX + '.EmbeddingName';
export const MODEL = PREFIX + '.Model';
export const MAX_TOKENS = ADVANCED_PREFIX + '.OpenAiMaxTokens';
export const SPLIT_CHUNK_SIZE = ADVANCED_PREFIX + '.SplitChunkSize';
export const SPLIT_CHUNK_OVERLAP = ADVANCED_PREFIX + '.SplitChunkOverlap';
export const RETRIEVED_DOCUMENT_SPLIT_COUNT = ADVANCED_PREFIX + '.RetrievedDocumentSplitCount';
export const LLM_PROVIDER_TOKEN_PER_MIN = ADVANCED_PREFIX + '.LlmProviderTokenPerMin';
export const CRAWLER_TYPE = ADVANCED_PREFIX + '.CrawlerType';

export const AZURE_API_KEY = AZURE_PREFIX + '.ApiKey';
export const AZURE_HAS_EXISTING_API_KEY = AZURE_PREFIX + '.HasExistingApiKey';
export const AZURE_EMBEDDING_NAME = AZURE_PREFIX + '.EmbeddingName';
export const AZURE_DEPLOYMENT_NAME = AZURE_PREFIX + '.DeploymentName';
export const AZURE_RESOURCE_NAME = AZURE_PREFIX + '.ResourceName';
export const AZURE_EMBEDDING_DEPLOYMENT = AZURE_PREFIX + '.EmbeddingDeployment';
export const AZURE_MAX_TOKENS = AZURE_ADVANCED_PREFIX + '.OpenAiMaxTokens';
export const AZURE_SPLIT_CHUNK_SIZE = AZURE_ADVANCED_PREFIX + '.SplitChunkSize';
export const AZURE_SPLIT_CHUNK_OVERLAP = AZURE_ADVANCED_PREFIX + '.SplitChunkOverlap';
export const AZURE_RETRIEVED_DOCUMENT_SPLIT_COUNT =
  AZURE_ADVANCED_PREFIX + '.RetrievedDocumentSplitCount';
export const AZURE_LLM_PROVIDER_TOKEN_PER_MIN = AZURE_ADVANCED_PREFIX + '.LlmProviderTokenPerMin';
export const AZURE_CRAWLER_TYPE = AZURE_ADVANCED_PREFIX + '.CrawlerType';

export const MAX_TOKENS_DEFAULT_VALUE = 256;
export const SPLIT_CHUNK_SIZE_DEFAULT_VALUE = 1000;
export const SPLIT_CHUNK_OVERLAP_DEFAULT_VALUE = 128;
export const RETRIEVED_DOCUMENT_SPLIT_COUNT_DEFAULT_VALUE = 3;
export const LLM_PROVIDER_TOKEN_PER_MIN_DEFAULT_VALUE = 30000;
export const CRAWLER_TYPE_DEFAULT_VALUE = 'playwright:adaptive';

export const UTTERANCE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me {0} utterances that user can say to you in {1}\ndescription: {2}\nintent name:{3}\n\nutterances:\n\n';
export const CONVERSATION_SUMMARIZATION_PHRASE =
  "###{0}\n###\nYour task is to generate a short summary of a call center dialog.\nInstructions:\nSummarize the main points of the above dialog, delimited by ###, in at most 45 words.\nYour answer must be in the conversation's language.";
export const TEXT_RESPONSE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me 1 utterance that chatbot can say to user similar to "{0},{1},{2}" sentence(s). Answer in language of "{0},{1},{2}"';

export const PROJECT_GENERATION_PHRASE =
  'Act as an intent and utterance generator that outputs in JSON format that represents an object with a key-value pair where the key is "intents", and the value is an array containing objects representing individual intents. Each intent object has two key-value pairs: "name", representing the name of the intent(without spaces), and "utterances", representing an array of sample phrases that users might say to express that intent. Return Json format not as markdown. Amounts: {0} intents, {1} utterances for each intent. Give intent and utterances in {2}. Context: {3}';

export const MODEL_OPTIONS = [
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-4-turbo',
  'gpt-4-turbo-preview',
  'gpt-4-1106-preview',
  'gpt-4-32k',
  'gpt-4',
  'gpt-3.5-turbo-instruct',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo',
];

export const EMBEDDING_NAME_OPTIONS = [
  'text-embedding-3-small',
  'text-embedding-3-large',
  'text-embedding-ada-002',
];

export const SUPPORTED_APIFY_CRAWLER_TYPE_OPTIONS = [
  {
    name: 'Adaptive switching between browser and raw HTTP',
    value: 'playwright:adaptive',
    tooltip: 'Fast renders JavaScript if needed. This is recommended option',
  },
  {
    name: 'Headless browser',
    value: 'playwright:firefox',
    tooltip: 'Reliable, renders JavaScript, best in avoiding blocking, but might be slow.',
  },
  {
    name: 'Raw HTTP client',
    value: 'cheerio',
    tooltip: 'Fastest crawler, but cannot render JavaScript',
  },
];
