<div>
  <h2>{{ 'Administration::RedactionSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="isEnableRedaction"
        formControlName="isEnableRedaction"
      />
      <label class="form-check-label" for="isEnableRedaction">
        {{ 'Administration::RedactionSettings:EnabledRedactionService' | abpLocalization }}
      </label>
      <br />
      <span class="form-text text-muted">{{
        'Administration::RedactionSettings:EnabledRedactionServiceInformation' | abpLocalization
      }}</span>
    </div>

    <div
      *ngIf="
        !redactionSettings.isRedactionServiceUnavailable && form.get('isEnableRedaction').value
      "
    >
      <h4 class="mt-4">
        {{ 'Administration::RedactionSettings:RedactionMode' | abpLocalization }}
      </h4>
      <div class="form-group">
        <div class="form-check mt-3">
          <input
            class="form-check-input"
            type="radio"
            id="replaceWithPIIType"
            formControlName="redactionMode"
            value="0"
          />
          <label class="form-check-label" for="replaceWithPIIType">{{
            'Administration::RedactionSettings:ReplaceWithPIIType' | abpLocalization
          }}</label>
          <br />
          <span class="form-text text-muted">{{
            'Administration::RedactionSettings:ReplaceWithPIITypeInformation' | abpLocalization
          }}</span>
        </div>

        <div class="form-check mt-3">
          <input
            class="form-check-input"
            type="radio"
            id="replaceWithCharacter"
            formControlName="redactionMode"
            value="1"
          />
          <label class="form-check-label" for="replaceWithCharacter">{{
            'Administration::RedactionSettings:ReplaceWithCharacter' | abpLocalization
          }}</label>
          <br />
          <span class="form-text text-muted">{{
            'Administration::RedactionSettings:ReplaceWithCharacterInformation' | abpLocalization
          }}</span>
        </div>
      </div>

      <h4 class="mt-4">
        {{ 'Administration::RedactionSettings:PIIToBeRedacted' | abpLocalization }}
      </h4>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="selectAll"
          formControlName="selectAll"
          (change)="onClickedSelectAll($event.currentTarget.checked)"
        />
        <label class="form-check-label" for="selectAll">
          {{ 'Administration::RedactionSettings:SelectAll' | abpLocalization }}
        </label>
      </div>
      <br />
      <div formGroupName="properties" class="properties-grid">
        <div *ngFor="let property of redactionSettings.properties" class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="{{ property.phraseType }}"
              formControlName="{{ property.phraseType }}"
              [checked]="property.isSelected"
              (change)="onPhraseTypeChanged()"
            />
            <label class="form-check-label" for="{{ property.phraseType }}">
              {{ getDisplayName(property) }}
              <span class="text-muted">{{ getLanguageCodes(property) }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <abp-button (click)="submitForm()" iconClass="fa fa-check" [loading]="isLoading">
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
