import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { FileContentResultModel } from 'src/app/shared/models/file-content-result-model';
import { FilterValueModel } from '../../shared/models/filter-panel.model';
import { TopIntentsModel } from '../models/top-intents-model';
import { AverageRatingModel } from '../models/average-rating-model';
import { TrendStatisticsModel } from '../models/trend-statistics.model';
import { AverageSentimentModel } from '../models/average-sentiment-model';
import { ConversationSessionStatisticsModel } from './conversation-session-statistics.model';
import { FallbackMessageStatisticsModel } from '../models/fallback-message-statistics.model';
import {
  DashboardIntentCreateUpdateDto,
  DashboardIntentDto,
  DashboardVirtualAgentDto,
} from './fallback-utterances.model';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  apiName = 'Default';

  constructor(private restService: RestService) {}

  getConversationSessionStatistics = (filterValue: FilterValueModel) =>
    this.restService.request<any, ConversationSessionStatisticsModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/conversation-session-statistics',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getIntentOverviewStatistics = (filterValue: FilterValueModel) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/app/dashboard/intent-overview-statistics',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getFallbackMessageStatistics = (filterValue: FilterValueModel) =>
    this.restService.request<any, FallbackMessageStatisticsModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/fallback-message-statistics',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getIntentTrendStatistics = (filterValue: FilterValueModel) =>
    this.restService.request<any, TrendStatisticsModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/intent-trends-statistics',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getTopIntents = (filterValue: FilterValueModel) =>
    this.restService.request<any, TopIntentsModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/top-intents',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getAverageRatings = (filterValue: FilterValueModel) =>
    this.restService.request<any, AverageRatingModel[]>(
      {
        method: 'POST',
        url: '/api/app/dashboard/average-ratings',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getSentimentTrendStatistics = (filterValue: FilterValueModel) =>
    this.restService.request<any, TrendStatisticsModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/sentiment-trends-statistics',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getAverageSentiment = (filterValue: FilterValueModel) =>
    this.restService.request<any, AverageSentimentModel[]>(
      {
        method: 'POST',
        url: '/api/app/dashboard/average-sentiment',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  getIntentListByVirtualAgentName = (virtualAgentName) =>
    this.restService.request<any, DashboardIntentDto[]>(
      {
        method: 'GET',
        url: `/api/app/dashboard/intent-list-by-virtual-agent/${virtualAgentName}`,
      },
      { apiName: this.apiName }
    );

  createUpdateIntent = (intentCreateUpdateDto: DashboardIntentCreateUpdateDto) =>
    this.restService.request<DashboardIntentCreateUpdateDto, DashboardVirtualAgentDto>(
      {
        method: 'POST',
        url: '/api/app/dashboard/intent-create-update',
        body: intentCreateUpdateDto,
      },
      { apiName: this.apiName }
    );

  getFallbackUtterancesFile = (filterValue: FilterValueModel) =>
    this.restService.request<any, FileContentResultModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/fallback-utterances-file',
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  exportTopIntents = (filterValue: FilterValueModel) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: '/api/app/dashboard/top-intents-excel-request',
        params: {
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        body: this.getParamsFromFilterValue(filterValue),
      },
      { apiName: this.apiName }
    );

  exportTopIntentsExcel = (id: string) =>
    this.restService.request<any, File>(
      {
        method: 'GET',
        url: `/api/app/dashboard/top-intents-excel-report/${id}`,
        responseType: 'blob' as 'json',
      },
      { apiName: this.apiName }
    );

  private getParamsFromFilterValue(filterValue: FilterValueModel) {
    return {
      channelNames: filterValue.channelNames,
      virtualAgentIds: filterValue.virtualAgentIds,
      projectIds: filterValue.projectIds,
      startDate: moment(filterValue.startDate).toISOString(true),
      endDate: moment(filterValue.endDate).toISOString(true),
    };
  }
}
