import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { RedactionSettingCreateOrUpdateDto, RedactionSettingDto } from './redaction-settings.model';
const PATH = '/api/app/redaction-settings';

@Injectable({
  providedIn: 'root',
})
export class RedactionSettingsService {
  apiName = 'Default';

  get = () =>
    this.restService.request<void, RedactionSettingDto>(
      {
        method: 'GET',
        url: `${PATH}`,
      },
      { apiName: this.apiName }
    );

  createOrUpdate = (input: RedactionSettingCreateOrUpdateDto) =>
    this.restService.request<RedactionSettingCreateOrUpdateDto, RedactionSettingDto>(
      {
        method: 'POST',
        url: `${PATH}/create-or-update`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
