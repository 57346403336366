import { downloadBlob } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';

@Injectable()
export class FileDownloadHelperService {
  constructor(private toasterService: ToasterService) {}

  handleDownloadFileResult(file: Blob, fileName: string) {
    if (file && file.size > 0) {
      downloadBlob(file, fileName);
    }
  }

  handleDownloadFileError(errorResponse: any) {
    if (errorResponse.error instanceof Blob && errorResponse.error.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const blobToJsonResult = JSON.parse(e.target.result as string);
        if (blobToJsonResult && blobToJsonResult.error && blobToJsonResult.error.message) {
          this.toasterService.error(blobToJsonResult.error.message, '::Error');
        }
      };
      reader.readAsText(errorResponse.error);
    } else {
      this.toasterService.error('::DownloadFileError');
    }
  }
}
