import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  OutboundConversationSettingCreateOrUpdateDto,
  OutboundConversationSettingDto,
} from './proxy/outbound-conversation-settings.model';
import { OutboundConversationSettingsService } from './proxy/outbound-conversation-settings.service';

@Component({
  selector: 'cai-outbound-conversation-settings',
  templateUrl: './outbound-conversation-settings.component.html',
  styleUrls: ['./outbound-conversation-settings.component.scss'],
})
export class OutboundConversationSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  outboundConversationSetting: OutboundConversationSettingDto;

  constructor(
    private fb: UntypedFormBuilder,
    private outboundConversationSettingsService: OutboundConversationSettingsService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.outboundConversationSettingsService.get().subscribe({
      next: (response) => {
        this.outboundConversationSetting = response;

        this.form = this.fb.group({
          outboundCallEndpoint: [
            this.outboundConversationSetting.outboundCallEndpoint,
            [Validators.required],
          ],
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  submitForm() {
    if (!this.form.valid) {
      return;
    }

    const outboundConversationSetting: OutboundConversationSettingCreateOrUpdateDto = {
      outboundCallEndpoint: this.form.get('outboundCallEndpoint').value,
    };

    this.outboundConversationSettingsService.createOrUpdate(outboundConversationSetting).subscribe(
      () => {
        this.toaster.success('::CustomSettings:Save:Success');
      },
      (error) => {
        this.toaster.error('::CustomSettings:Save:Error');
        console.log(error);
      }
    );
  }
}
