import { ObjectHelper } from 'src/app/shared/helpers/object.helper';
import { DaysOfWeek } from 'src/app/shared/shared.models';
import { OutboundRulesDto, OutboundTimeIntervalDto } from './outbound-rules.models';

export const OUTBOUND_FEATURE_OUTBOUND_CONVERSATIONS = 'OutboundGroup.OutboundConversations';

export const MIN_MAX_ATTEMPT_COUNT = 1;
export const MAX_MAX_ATTEMPT_COUNT = 10;
export const MIN_MAX_BUSY_COUNT = 1;
export const MAX_MAX_BUSY_COUNT = 10;
export const MIN_MAX_NO_ANSWER_COUNT = 1;
export const MAX_MAX_NO_ANSWER_COUNT = 10;
export const MIN_MAX_REJECT_COUNT = 1;
export const MAX_MAX_REJECT_COUNT = 10;
export const MIN_MAX_ERROR_COUNT = 1;
export const MAX_MAX_ERROR_COUNT = 10;
export const MIN_MAX_UNAVAILABLE_COUNT = 1;
export const MAX_MAX_UNAVAILABLE_COUNT = 10;
export const MIN_WAIT_DURATION_FOR_NEXT_CALL = 1;
export const MAX_WAIT_DURATION_FOR_NEXT_CALL = 300;

export const OUTBOUND_TIME_INTERVALS = [
  { title: '00:00', value: 0 },
  { title: '00:30', value: 30 },
  { title: '01:00', value: 60 },
  { title: '01:30', value: 90 },
  { title: '02:00', value: 120 },
  { title: '02:30', value: 150 },
  { title: '03:00', value: 180 },
  { title: '03:30', value: 210 },
  { title: '04:00', value: 240 },
  { title: '04:30', value: 270 },
  { title: '05:00', value: 300 },
  { title: '05:30', value: 330 },
  { title: '06:00', value: 360 },
  { title: '06:30', value: 390 },
  { title: '07:00', value: 420 },
  { title: '07:30', value: 450 },
  { title: '08:00', value: 480 },
  { title: '08:30', value: 510 },
  { title: '09:00', value: 540 },
  { title: '09:30', value: 570 },
  { title: '10:00', value: 600 },
  { title: '10:30', value: 630 },
  { title: '11:00', value: 660 },
  { title: '11:30', value: 690 },
  { title: '12:00', value: 720 },
  { title: '12:30', value: 750 },
  { title: '13:00', value: 780 },
  { title: '13:30', value: 810 },
  { title: '14:00', value: 840 },
  { title: '14:30', value: 870 },
  { title: '15:00', value: 900 },
  { title: '15:30', value: 930 },
  { title: '16:00', value: 960 },
  { title: '16:30', value: 990 },
  { title: '17:00', value: 1020 },
  { title: '17:30', value: 1050 },
  { title: '18:00', value: 1080 },
  { title: '18:30', value: 1110 },
  { title: '19:00', value: 1140 },
  { title: '19:30', value: 1170 },
  { title: '20:00', value: 1200 },
  { title: '20:30', value: 1230 },
  { title: '21:00', value: 1260 },
  { title: '21:30', value: 1290 },
  { title: '22:00', value: 1320 },
  { title: '22:30', value: 1350 },
  { title: '23:00', value: 1380 },
  { title: '23:30', value: 1410 },
  { title: '24:00', value: 1440 },
];
export const PROJECT_DEFAULT_OUTBOUND_RULES: OutboundRulesDto = {
  outboundTimeIntervals: ObjectHelper.getEnumKeyValuePairs(DaysOfWeek).map(
    (dayOfWeek) =>
      ({
        dayOfWeek: dayOfWeek.key,
        fromMinuteOfDay: 870,
        toMinuteOfDay: 1050,
        isEnabled: true,
      } as OutboundTimeIntervalDto)
  ),
  outboundCallRule: {
    maxAttemptCount: 5,
    maxRejectCount: 3,
    maxBusyCount: 5,
    maxNoAnswerCount: 5,
    maxErrorCount: 5,
    maxUnavailableCount: 5,
    waitDurationForNextCall: 60,
  },
};
