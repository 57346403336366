import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationDto } from 'src/app/integrations/proxy/integration.model';

@Pipe({ name: 'integrationTooltipFormatter' })
export class IntegrationTooltipFormatterPipe implements PipeTransform {
  constructor() {}

  transform(value: IntegrationDto): string {
    return `${value?.provider}-${value?.platform}`;
  }
}
