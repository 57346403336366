import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';
import { RICH_TEXT_EDITOR_EMPTY_VALUE } from '../../proxy/rich-text-editor.const';
import { MentionModel, QuillValueModel } from '../../proxy/rich-text-editor.model';
import Delta from 'quill-delta';

@Component({
  selector: 'cai-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextEditorComponent implements OnInit {
  @Input() availableMentions: MentionModel[] = [];
  @Input() placeholder = '';
  @Input() required = false;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() isRichTextSupported = false;
  @Input() spaceAfterInsert = true;
  @Input() trim = false;
  @Input() autofocus = false;
  @Input() readOnly = false;
  @Input() isPercentIconVisible = true;
  @Input() content: string;
  @Input() isMentionReadOnly = false;

  @Output() contentChanged = new EventEmitter<Delta>();
  @Output() addMention: EventEmitter<void> = new EventEmitter();
  @Output() editMention: EventEmitter<MentionModel> = new EventEmitter();
  @Output() removeMention: EventEmitter<MentionModel> = new EventEmitter();

  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  @ViewChild('mentionListIcon', { static: false }) mentionListIcon: ElementRef;

  formats: string[] = ['mention', 'bold', 'italic', 'underline', 'link', 'background'];
  modules: QuillModules = {
    mention: {
      allowedChars: /^[A-Za-z0-9\[\]\.]*$/,
      mentionDenotationChars: ['%'],
      showDenotationChar: false,
      dataAttributes: ['key', 'type'],
      onSelect: (item) => this.insertItem(item, false),
      source: (searchTerm, renderList) => {
        let availableMentions = this.availableMentions;

        if (searchTerm.length) {
          availableMentions = availableMentions.filter(
            (mention) => mention.key.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );

          availableMentions.unshift({
            key: searchTerm,
            value: searchTerm,
            type: 'custom',
          });
        }

        renderList(availableMentions, searchTerm);
      },
      renderItem: (item) => {
        return `${item.key}`;
      },
    },
    toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
  };

  showMentionListModal = false;
  private isBlurEventListenerAdded = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = {
        writeValue: () => {},
        registerOnChange: () => {},
        registerOnTouched: () => {},
      };
    }
  }

  ngOnInit() {
    if (!this.isRichTextSupported) {
      this.modules.toolbar = false;
      this.formats = ['mention'];
    }

    if (this.spaceAfterInsert === undefined) {
      this.spaceAfterInsert = true;
    }

    this.modules.mention.spaceAfterInsert = this.spaceAfterInsert;

    if (this.ngControl) {
      this.setQuillValue();

      this.ngControl.control.addValidators(this.editor.validate.bind(this.editor));

      this.ngControl.control.valueChanges.subscribe(() => {
        this.setQuillValue();
      });
    }
  }

  setQuillValue() {
    let quillValue: QuillValueModel;
    try {
      quillValue = JSON.parse(this.ngControl.control.value);
    } catch {}

    if (quillValue && quillValue.ops && quillValue.ops.length) {
      const lastOp = quillValue.ops[quillValue.ops.length - 1];
      if (typeof lastOp.insert === 'string') {
        lastOp.insert += '\n';
      }
    } else {
      quillValue = { ops: [{ insert: this.ngControl.control.value || '' }] };

      if (this.ngControl.control.value === null || this.ngControl.control.value === undefined) {
        setTimeout(() => this.ngControl.control.setValue(''));
      }
    }

    if (this.content !== JSON.stringify(quillValue)) {
      this.content = JSON.stringify(quillValue);
    }
  }

  onContentChanged(contentChange: ContentChange) {
    // quill bug fixed - begin
    const mentionLength = contentChange.html?.match(/<span class="mention"/)?.length ?? 0;
    const denotationCharLength =
      contentChange.html?.match(/<span class="ql-mention-denotation-char"/)?.length ?? 0;

    if (mentionLength !== denotationCharLength) {
      // quill bug occurred
      contentChange.editor.setContents(RICH_TEXT_EDITOR_EMPTY_VALUE);
      return;
    }
    // end

    const content: Delta = JSON.parse(JSON.stringify(contentChange.editor.getContents()));
    const lastOp = content.ops[content.ops.length - 1];

    if (lastOp && typeof lastOp.insert === 'string') {
      if (lastOp.insert.endsWith('\n')) {
        lastOp.insert = lastOp.insert.slice(0, -1);
      }
    }

    if (this.ngControl) {
      const value = JSON.stringify(content);

      if (value !== this.ngControl.control.value) {
        this.ngControl.control.setValue(value);

        this.contentChanged.emit(content);
      }
    }
  }

  onFocus() {
    if (!this.isBlurEventListenerAdded) {
      this.editor.quillEditor.root.addEventListener('blur', () => {
        this.onBlur();
      });
      this.isBlurEventListenerAdded = true;
    }
  }

  onBlur() {
    if (this.trim) {
      const currentContent = JSON.parse(JSON.stringify(this.editor.quillEditor.getContents()));
      const newContent: Delta = JSON.parse(JSON.stringify(currentContent));
      let startIndex: number = null;
      let endIndex: number = null;

      const lastOp = currentContent.ops[currentContent.ops.length - 1];

      if (lastOp && typeof lastOp.insert === 'string') {
        if (lastOp.insert.endsWith('\n')) {
          lastOp.insert = lastOp.insert.slice(0, -1);
        }
      }

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < newContent.ops.length; index++) {
        const firstOpIndex = index;
        const lastOpIndex = newContent.ops.length - 1 - index;

        if (firstOpIndex > lastOpIndex || (startIndex !== null && endIndex !== null)) {
          break;
        }

        if (startIndex === null) {
          const op = newContent.ops[firstOpIndex];

          if (typeof op.insert === 'string') {
            op.insert = op.insert.replace(/^\s+/, '');

            if (op.insert.length > 0) {
              startIndex = firstOpIndex;
            }
          } else {
            startIndex = firstOpIndex;
          }
        }

        if (endIndex === null) {
          const op = newContent.ops[lastOpIndex];

          if (typeof op.insert === 'string') {
            op.insert = op.insert.replace(/\s+$/, '');

            if (op.insert.length > 0) {
              endIndex = lastOpIndex;
            }
          } else {
            endIndex = lastOpIndex;
          }
        }
      }

      if (startIndex === null || endIndex === null) {
        startIndex = endIndex = 0;
      }

      newContent.ops = newContent.ops.slice(startIndex, endIndex + 1);

      if (
        currentContent.ops.length !== newContent.ops.length ||
        JSON.stringify(currentContent) !== JSON.stringify(newContent)
      ) {
        this.editor.quillEditor.setContents(newContent);

        if (this.ngControl) {
          this.ngControl.control.setValue(JSON.stringify(newContent));

          this.contentChanged.emit(newContent);
        }
      }
    }
  }

  onAddMention() {
    this.addMention.emit();
  }

  onEditMention(mention: MentionModel) {
    this.editMention.emit(mention);
  }

  onRemoveMention(mention: MentionModel) {
    this.removeMention.emit(mention);
  }

  insertItem(mention: MentionModel, programmaticInsert: boolean) {
    const entry: any = { ...mention };

    delete entry.index;

    const mentionModule = this.editor.quillEditor.getModule('mention');

    mentionModule.cursorPos =
      mentionModule.cursorPos ?? this.editor.quillEditor.scroll.length() - 1;

    mentionModule.insertItem(entry, programmaticInsert);
  }

  onMentionSelected(mention: MentionModel) {
    this.insertItem(mention, true);
  }

  toggleMentionListModal() {
    this.showMentionListModal = !this.showMentionListModal;
  }

  setFocus(editor) {
    if (this.autofocus) {
      editor.focus();
    }
  }
}
