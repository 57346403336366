<ng-container>
  <div class="chatbot d-flex flex-column test-call-wrapper">
    <div style="height: calc(100vh - 267px) !important">
      <div class="mt-2 mb-3 pb-2 ps-2 test-call-title">
        {{ 'Project::Outbound:TestCall' | abpLocalization }}
      </div>
      <div *blockUI="'test-call-block-ui'" class="h-100">
        <div class="d-flex justify-content-start">
          <form [formGroup]="destinationForm" class="w-100">
            <div class="row">
              <div class="d-flex flex-row col-12">
                <div class="col-6 ps-0 d-flex align-items-center">
                  <span class="pe-1">{{ 'Project::Outbound:Destination' | abpLocalization }}:</span>
                </div>
                <div class="col-6 ps-1 d-flex flex-row">
                  <div class="flex-column w-100">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="destinationPhoneNumber"
                    />
                  </div>
                  <div style="width: 72px"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- parameters start  -->
        <div class="row mt-3">
          <div class="col-12">
            <span class="pe-1">{{ 'Project::Outbound:Parameters' | abpLocalization }}</span>
            <span>
              <i
                ngbTooltip="{{ 'Project::Outbound:ParametersTooltip' | abpLocalization }}"
                class="c-pointer fa fa-info-circle information-icon"
              >
              </i>
            </span>
          </div>
        </div>
        <div class="row me-0 mt-2 pt-1 parameters-panel">
          <div class="col-12">
            <div [formGroup]="formGroup">
              <div formArrayName="array">
                <div *ngFor="let controlItem of formArray.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="row mb-1">
                      <div class="col-6">
                        <ng-select
                          notFoundText="{{ '::NotFound' | abpLocalization }}"
                          formControlName="key"
                          appendTo="body"
                          [multiple]="false"
                          [closeOnSelect]="true"
                          (change)="onControlValueChanged()"
                        >
                          <ng-option *ngFor="let item of projectDynamicContexts" [value]="item.key">
                            {{ item.key }}
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="dynamic-select-wrapper col-6 d-flex flex-row">
                        <div class="flex-column w-100">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            formControlName="value"
                            (ngModelChange)="onControlValueChanged()"
                          />
                        </div>
                        <button
                          class="btn btn-outline-primary shadow-none border-0"
                          type="button"
                          (click)="removeParameter(i)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row me-0">
          <div class="col-12">
            <div
              class="col-12 d-flex justify-content-end pe-0"
              style="padding-right: 48px !important"
            >
              <button
                type="button"
                class="btn btn-outline-primary btn-xs rounded p-1"
                (click)="addParameter()"
                placement="top"
                container="body"
                [disabled]="formArray.controls.length >= maxLengthOfKeyValuePairsAllowed"
                style="width: 25px; margin-top: 4px"
              >
                <i class="fa fa-plus fa-xs rich-content-add-button-icon"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- parameters end  -->

        <div class="row me-0 mt-1">
          <div class="col-12">
            <div
              class="col-12 d-flex justify-content-end pe-0"
              style="padding-right: 48px !important"
            >
              <button
                type="button"
                class="btn btn-primary btn-sm mt-3"
                style="width: 130px"
                [disabled]="destinationForm.invalid || !formArray.valid"
                (click)="makeTestCall()"
              >
                {{ 'Project::Outbound:Test' | abpLocalization }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="call-result-wrapper">
        <span>{{ 'Project::Outbound:CallResult' | abpLocalization }}:</span>
        <textarea
          readonly
          style="background-color: white !important"
          class="form-control no-resize mt-1"
          rows="4"
          cols="80"
          resize=""
          [ngModel]="callResultContent"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </div>
    </div>
  </div>
</ng-container>
