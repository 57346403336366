import { Component, OnInit } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import {
  CHAT_ENDPOINT_ADDRESS,
  CLIENT_ID,
  CLIENT_SECRET,
  IS_ENABLED,
  START_DATE,
  TOKEN_ENDPOINT_ADDRESS,
} from './ca-integration.consts';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomSettingsDto } from '../proxy/custom-settings.model';
import { CustomSettingsService } from '../proxy/custom-settings.service';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'cai-ca-integration-settings',
  templateUrl: './ca-integration-settings.component.html',
  styleUrls: ['./ca-integration-settings.component.scss'],
})
export class CaIntegrationSettingsComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private config: ConfigStateService,
    private fb: UntypedFormBuilder,
    private settingsService: CustomSettingsService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  settingValueToBoolean(value: string): boolean {
    return value === 'True' ? true : false;
  }

  booleanToSettingValue(value: boolean): string {
    return value === true ? 'True' : 'False';
  }

  buildForm() {
    const isEnabledValue = this.settingValueToBoolean(this.config.getSetting(IS_ENABLED));
    this.form = this.fb.group({
      isEnabled: [isEnabledValue],
      chatEndpointAddress: [this.config.getSetting(CHAT_ENDPOINT_ADDRESS), [Validators.required]],
      tokenEndpointAddress: [this.config.getSetting(TOKEN_ENDPOINT_ADDRESS), [Validators.required]],
      clientId: [this.config.getSetting(CLIENT_ID), [Validators.required]],
      clientSecret: [this.config.getSetting(CLIENT_SECRET), [Validators.required]],
      startDate: [this.config.getSetting(START_DATE), [Validators.required]],
    });
  }

  submitForm() {
    if (!this.form.valid) {
      return;
    }

    const settings: CustomSettingsDto[] = [];
    settings.push(
      {
        name: IS_ENABLED,
        value: this.booleanToSettingValue(this.form.get('isEnabled').value),
      },
      {
        name: CHAT_ENDPOINT_ADDRESS,
        value: this.form.get('chatEndpointAddress').value,
      },
      {
        name: TOKEN_ENDPOINT_ADDRESS,
        value: this.form.get('tokenEndpointAddress').value,
      },
      {
        name: CLIENT_ID,
        value: this.form.get('clientId').value,
      },
      {
        name: CLIENT_SECRET,
        value: this.form.get('clientSecret').value,
      },
      {
        name: START_DATE,
        value: this.form.get('startDate').value,
      }
    );

    this.settingsService.save(settings).subscribe(
      () => {
        this.toaster.success('::CustomSettings:Save:Success');
      },
      (error) => {
        this.toaster.error('::CustomSettings:Save:Error');
        console.log(error);
      }
    );
  }
}
