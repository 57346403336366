import { ToasterService } from '@abp/ng.theme.shared';
import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { OutboundCallResultModel } from './outbound-test-call.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OutboundTestCallService {
  connected = false;
  connection: HubConnection;
  receiveMessages = new EventEmitter<OutboundCallResultModel>();
  startConversationEmitter = new EventEmitter();
  checkConversationState = new EventEmitter();
  getConversationId = new EventEmitter();

  outboundTestCallExecutionCompleted = new Subject<void>();

  constructor(private toasterService: ToasterService) {}

  createConnection() {
    const url = `${environment.apis.default.url}/api/hubs/outboundTestCallHub`;

    this.connection = new HubConnectionBuilder()
      .withAutomaticReconnect([0, 0, 10000])
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();
  }

  connectAsync = async () => {
    if (this.connection === undefined) {
      this.createConnection();
    }
    this.connected = true;
    await this.connection.start().catch((error) => {
      console.error('OutboundTestCallService - connectAsync error: ', error);
      this.toasterService.error('Project::Outbound:TestCallGenericError');
      this.outboundTestCallExecutionCompleted.next();
    });
  };

  sendCallRequestAsync = async (...args) => {
    if (!this.connected) {
      const value = await this.reconnectAsync();
      return value;
    }

    const result = await this.connection.invoke('SendCallRequestAsync', ...args).catch((error) => {
      this.connected = false;
      console.error('OutboundTestCallService - sendCallRequestAsync error: ', error);
      this.toasterService.error('Project::Outbound:TestCallGenericError');
      this.outboundTestCallExecutionCompleted.next();
    });

    if (!result.isSuccess) {
      this.toasterService.error(result.errorMessage);
      this.outboundTestCallExecutionCompleted.next();
    }
  };

  receiveCallResult = async () => {
    await this.connection.on('ReceiveCallResult', (resultString: string) => {
      const result: OutboundCallResultModel = JSON.parse(resultString);

      if (result.isSuccess) {
        this.receiveMessages.emit(result);
      } else {
        this.toasterService.error(result.errorMessage);
      }
      this.outboundTestCallExecutionCompleted.next();
    });
  };

  reconnectAsync = async () => {
    await this.createConnection();
    await this.connectAsync();
  };

  close = async () => {
    await this.connection.stop();
    this.connected = false;
    this.connection = undefined;
    console.log('Test Call closed!');
  };
}
