<nav class="navbar navbar-expand-lg user-nav-mobile">
  <div
    class="navbar-collapse d-lg-block toolbar-nav-wrapper collapse"
    id="navbarToolbar"
    [class.show]="smallScreen"
    [class.abp-collapsed-height]="smallScreen"
    [class.abp-mh-100]="smallScreen && isCollapseNavbarTool"
  >
    <ul class="navbar-nav toolbar-nav ms-auto">
      <li class="nav-item d-flex align-items-center">
        <abp-back-to-impersonator-nav-item></abp-back-to-impersonator-nav-item>
      </li>

      <li class="nav-item" *ngIf="caiRoutesService?.currentProjectId">
        <a
          class="btn btn-xs shadow-none bubble-btn"
          [routerLink]="['/projects', caiRoutesService.currentProjectId, 'edit', 'general']"
          [queryParams]="{ source: 'designer' }"
          [ngbTooltip]="'Project::ProjectSettings' | abpLocalization"
          *abpPermission="'Designer.Projects.Edit'"
          style="padding: 5px !important"
        >
          <i class="fa fa-gear fa-lg"></i>
        </a>
      </li>

      <li class="nav-item">
        <sestek-notification-icon [routerLinkBase]="routerLinkBase"></sestek-notification-icon>
      </li>

      <li class="nav-item d-flex align-items-center">
        <cai-languages></cai-languages>
      </li>

      <li class="nav-item" *ngIf="currentUser$ | async as user">
        <ng-template #loginBtn>
          <a role="button" routerLink="/account/login" class="btn">{{
            'AbpAccount::Login' | abpLocalization
          }}</a>
        </ng-template>
        <div *ngIf="user.isAuthenticated; else loginBtn" class="dropdown btn-group" ngbDropdown>
          <a ngbDropdownToggle class="btn pointer">
            <span class="user-avatar">
              <i class="fas fa-user fa-lg"></i>
            </span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
            <div class="p-2 row">
              <div class="pe-0 pt-2 mb-2 col col-auto">
                <span class="user-avatar-big">
                  <i class="fas fa-user fa-lg"></i>
                </span>
              </div>
              <div class="ps-2 col">
                <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
                ><br />
                <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
                  ><i>{{ tenantName }}</i></small
                >
                <strong>{{ user.userName }}</strong>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item pointer" (click)="onClickManageProfile()">{{
              'AbpAccount::MyAccount' | abpLocalization
            }}</a>
            <a class="dropdown-item pointer" (click)="onClickSecurityLogs()">
              {{ 'AbpAccount::MySecurityLogs' | abpLocalization }}
            </a>
            <a class="dropdown-item pointer" (click)="openVersionModal(versionInfoModal)">{{
              '::About' | abpLocalization
            }}</a>
            <a class="dropdown-item pointer" id="logout" (click)="logout()">{{
              'AbpUi::Logout' | abpLocalization
            }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<ng-template #versionInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center"><abp-logo></abp-logo></h4>
  </div>
  <div class="modal-body">
    <cai-version-info></cai-version-info>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>
