import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { RavenMetaDataListDto } from 'src/app/integrations/proxy/integration.model';
import { IntentFilterDto, ProjectVirtualAgentDto } from 'src/app/projects/proxy/project.models';

@Injectable({ providedIn: 'root' })
export class FilterService {
  apiName = 'Default';

  constructor(private restService: RestService) {}

  getVirtualAgentsByProjectId = (projectId: string) =>
    this.restService.request<void, ProjectVirtualAgentDto[]>(
      {
        method: 'GET',
        url: `/api/app/filters/virtual-agents-by-project-id/${projectId}`,
      },
      { apiName: this.apiName }
    );

  getBackwardCompatibleChannelIntegrationTypes = () =>
    this.restService.request<void, RavenMetaDataListDto>(
      {
        method: 'GET',
        url: '/api/app/filters/channel-integration-name-list/backward-compatible',
      },
      { apiName: this.apiName }
    );

  getIntentsByProjectId = (id: string) =>
    this.restService.request<void, IntentFilterDto[]>(
      {
        method: 'GET',
        url: `/api/app/filters/get-intents-by-project-Id/${id}`,
      },
      { apiName: this.apiName }
    );
}
