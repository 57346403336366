import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';

import { VirtualAgentTrainedNotificationModel } from '../models/virtual-agent-trained-notification-model';

@Component({
  selector: 'cai-virtual-agent-trained-notification',
  templateUrl: './virtual-agent-trained-notification.component.html',
  styleUrls: ['./virtual-agent-trained-notification.component.scss'],
})
@NotificationType('VirtualAgentTrainedNotification')
export class VirtualAgentTrainedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: VirtualAgentTrainedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = `${this.localizationService.instant(
      'Project::VirtualAgentTrainedSuccessfully'
    )} ${this.notification.name}`;

    this.toasterService.success(message);
  }
}
