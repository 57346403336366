import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateCharacters',
})
export class TruncateCharactersPipe implements PipeTransform {
  transform(
    value: string,
    characterLimit: number
  ): { truncatedContent: string; isTruncated: boolean } {
    if (!value) {
      return { truncatedContent: '', isTruncated: false };
    }

    if (value.length <= characterLimit) {
      return { truncatedContent: value, isTruncated: false };
    }

    return { truncatedContent: value.substring(0, characterLimit) + '...', isTruncated: true };
  }
}
