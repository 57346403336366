import { Component, OnDestroy, OnInit } from '@angular/core';
import { OutboundTestCallService } from '../proxy/outbound-test-call.service';
import { Subscription } from 'rxjs';
import {
  KeyValuePair,
  OutboundCallRequestModel,
  OutboundCallResultModel,
} from '../proxy/outbound-test-call.model';
import { CaiRoutesService } from '../../routes/cai-routes.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MentionModel } from '../../rich-text/proxy/rich-text-editor.model';
import { DesignerService } from '../../designer/proxy/designer.service';
import { ActivatedRoute } from '@angular/router';
import { ContextConverter, getStorageKey } from '../../designer/utils';
import { ContextDto } from '../../designer/proxy/designer.model';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { OUTBOUND_TEST_CALL_PARAMETERS_KEY } from '../../shared.consts';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DatePipe } from '@angular/common';
import { DurationPipe } from '../../pipes/duration-formatter.pipe';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'cai-outbound-test-call-panel',
  templateUrl: './outbound-test-call-panel.component.html',
  styleUrls: ['./outbound-test-call-panel.component.scss'],
  providers: [DatePipe, DurationPipe],
})
export class OutboundTestCallPanelComponent implements OnInit, OnDestroy {
  projectVersionId: string = null;
  receiveCallResultsSubscription: Subscription;
  callResultContent: string;

  formGroup: UntypedFormGroup;
  projectDynamicContexts: MentionModel[] = [];
  destinationForm: UntypedFormGroup;
  outboundTestCallFailedSubjectSubscription: Subscription;
  formArrayStatusSubscription: Subscription;

  private readonly destinationKey = '_Destination';

  @BlockUI('test-call-block-ui') testCallBlockUI: NgBlockUI;

  private readonly maxLengthOfKeyValuePairsAllowed = 10;
  private readonly timeoutDuration = 15 * 60 * 1000;

  constructor(
    private outboundTestCallService: OutboundTestCallService,
    private designerService: DesignerService,
    private activatedRoute: ActivatedRoute,
    private configStateService: ConfigStateService,
    private fb: UntypedFormBuilder,
    private localizationService: LocalizationService,
    private datePipe: DatePipe,
    private durationPipe: DurationPipe,
    private toasterService: ToasterService,
    private caiRoutesService: CaiRoutesService
  ) {}

  async ngOnInit() {
    this.projectVersionId = this.activatedRoute.snapshot.paramMap?.get('projectVersionId');
    this.getProjectContextObjects();

    this.outboundTestCallFailedSubjectSubscription =
      this.outboundTestCallService.outboundTestCallExecutionCompleted.subscribe(() => {
        this.testCallBlockUI.stop();
      });

    this.destinationForm = this.fb.group({
      destinationPhoneNumber: [null, [Validators.required]],
    });

    const formGroupArray = [];
    const cachedParameters = this.getCachedParameters();

    if (cachedParameters) {
      cachedParameters.parameters.forEach((element: any) => {
        formGroupArray.push(
          this.fb.group({
            key: [element.key, Validators.required],
            value: [element.value, Validators.required],
          })
        );
      });

      this.destinationForm.get('destinationPhoneNumber').patchValue(cachedParameters.destination);
    }

    this.formGroup = this.fb.group({
      array: this.fb.array(formGroupArray),
    });

    await this.outboundTestCallService.connectAsync();

    await this.outboundTestCallService.receiveCallResult();
    this.receiveCallResultsSubscription = this.outboundTestCallService.receiveMessages.subscribe(
      (callResult: OutboundCallResultModel) => {
        const formattedRequestTime = this.datePipe.transform(callResult.callRequestTime, 'short');
        const formattedEndTime = this.datePipe.transform(callResult.callEndTime, 'short') ?? '-';
        const formattedDuration =
          callResult.callDuration === 0
            ? '-'
            : this.durationPipe.transform(callResult.callDuration / 1000);

        const callResultStateLabel = this.localizationService.instant(
          'Project::Outbound:CallResult:CallResultState'
        );

        const callRequestTimeLabel = this.localizationService.instant(
          'Project::Outbound:CallResult:CallRequestTime'
        );

        const callEndTimeLabel = this.localizationService.instant(
          'Project::Outbound:CallResult:CallEndTime'
        );

        const callDurationLabel = this.localizationService.instant(
          'Project::Outbound:CallResult:CallDuration'
        );

        let resultContent = `${callResultStateLabel}: ${callResult.callResultState}\n`;
        resultContent += `${callRequestTimeLabel}: ${formattedRequestTime}\n`;
        resultContent += `${callEndTimeLabel}: ${formattedEndTime}\n`;
        resultContent += `${callDurationLabel}: ${formattedDuration}\n`;

        this.callResultContent = resultContent;
      }
    );
  }

  private getCachedParameters(): any {
    const storageKey = getStorageKey(this.configStateService, OUTBOUND_TEST_CALL_PARAMETERS_KEY);
    const cachedValuesStr = localStorage.getItem(storageKey);

    return cachedValuesStr
      ? JSON.parse(cachedValuesStr)[this.caiRoutesService.currentProjectId]
      : null;
  }

  private cacheParameters(destination: any, parameters: any) {
    const storageKey = getStorageKey(this.configStateService, OUTBOUND_TEST_CALL_PARAMETERS_KEY);
    let cachedValuesStr = localStorage.getItem(storageKey);
    let cachedValues = new Map<string, any>();
    if (cachedValuesStr) {
      cachedValues = JSON.parse(cachedValuesStr);
    }

    const cachedValue = cachedValues[this.caiRoutesService.currentProjectId] ?? {};

    cachedValue.destination = destination;
    cachedValue.parameters = parameters;
    cachedValues[this.caiRoutesService.currentProjectId] = cachedValue;
    cachedValuesStr = JSON.stringify(cachedValues);
    localStorage.setItem(storageKey, cachedValuesStr);
  }

  async makeTestCall() {
    if (this.destinationForm.invalid || this.formArray.invalid) {
      return;
    }

    this.callResultContent = '';

    setTimeout(() => {
      this.testCallBlockUI.stop();
      this.toasterService.error('Project::Outbound:CallResult:CallResultTimedOut');
    }, this.timeoutDuration);

    this.testCallBlockUI.start();

    const parameters: KeyValuePair[] = [];
    const destinationPhoneNumberValue = this.destinationForm.get('destinationPhoneNumber').value;
    const parametersFormRawValue = this.formArray.getRawValue();

    this.cacheParameters(destinationPhoneNumberValue, parametersFormRawValue);

    parameters.push({ key: this.destinationKey, value: destinationPhoneNumberValue });

    parametersFormRawValue.forEach((element) => {
      const key = element.key;
      const value = element.value;
      parameters.push({ key: key, value: value });
    });

    const callRequest: OutboundCallRequestModel = {
      projectId: this.caiRoutesService.currentProjectId,
      parameters: parameters,
    };

    await this.outboundTestCallService.sendCallRequestAsync(callRequest);
  }

  async ngOnDestroy() {
    await this.outboundTestCallService.close();
    if (this.receiveCallResultsSubscription) {
      this.receiveCallResultsSubscription.unsubscribe();
    }

    this.outboundTestCallFailedSubjectSubscription?.unsubscribe();
    this.formArrayStatusSubscription?.unsubscribe();
  }

  get formArray(): UntypedFormArray {
    return this.formGroup.controls.array as UntypedFormArray;
  }

  addParameter() {
    if (this.formArray.controls.length >= this.maxLengthOfKeyValuePairsAllowed) {
      return;
    }

    const newParameter = this.createParameter();
    this.formArray.controls.push(newParameter);

    // Update validity of the new control and the FormArray
    newParameter.updateValueAndValidity();
    this.formArray.updateValueAndValidity();
  }

  createParameter() {
    return this.fb.group({
      key: [null, { validators: [Validators.required] }],
      value: [null, { validators: [Validators.required] }],
    });
  }

  onControlValueChanged() {
    this.formArray.updateValueAndValidity();
  }

  removeParameter(index) {
    this.formArray.removeAt(index);
  }

  getProjectContextObjects() {
    if (this.projectVersionId) {
      this.designerService
        .getProjectContextObjects(this.projectVersionId)
        .subscribe((res: ContextDto) => {
          const contexts: any = ContextConverter(res);
          this.projectDynamicContexts = contexts
            .filter((context) => context.type === 'dynamic')
            .map((context) => ({
              key: context.key,
              value: context.value,
              type: context.type,
            }));
        });
    }
  }
}
